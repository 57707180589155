/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState, useCallback } from "react";

import { ActionButtons, THead } from "./components";
import { TableContainer } from "./styles";

interface Request {
  actions?: boolean;
  tbody: string[][];
  thead: string[];
  tbuttons: string[];
  tuploads: string[];
  handleExistingValues: Function;
}

const Table: React.FC<Request> = ({
  actions = true,
  tuploads,
  tbody,
  thead,
  tbuttons,
  handleExistingValues,
}) => {
  const [arrayIndex, setArrayIndex] = useState<number>();
  const [isEmptyTable, setIsEmptyTable] = useState<boolean>(false);

  const verifyIfIsAnEmptyTable = useCallback(() => {
    let isEmpty = true;
    if (tbody.length !== 1) {
      return;
    }

    tbody[0].forEach((item) => {
      if (item !== "") {
        isEmpty = false;
      }
    });

    setIsEmptyTable(isEmpty);
  }, [tbody]);

  useEffect(() => {
    (() => {
      thead.forEach((header, index) =>
        tuploads.forEach((upload) => {
          if (upload === header) setArrayIndex(index);
        })
      );
    })();

    verifyIfIsAnEmptyTable();
  }, [thead, tuploads, verifyIfIsAnEmptyTable]);

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            {actions ? <th>Ações</th> : null}
            {thead.map((head) => (
              <THead label={head} />
            ))}
          </tr>
        </thead>
        <tbody>
          {!isEmptyTable &&
            tbody.map((body) => (
              <tr>
                {actions ? (
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "35%",
                    }}
                  >
                    {tbuttons.map((buttonType) => (
                      <ActionButtons
                        buttonType={buttonType}
                        handleExistingValues={handleExistingValues}
                        id={parseInt(body[0], 10)}
                      />
                    ))}
                  </td>
                ) : null}

                {body.map((item, index) =>
                  index === arrayIndex ? (
                    <td>
                      <a href={`http://localhost:3334/${item}`}>{item}</a>
                    </td>
                  ) : (
                    <td>{item}</td>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default Table;
