import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;

  flex: 1;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
`;
