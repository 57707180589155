import styled from "styled-components";

export const CardContainer = styled.div`
  box-shadow: 0px 0px 15px -5px #666;

  display: flex;
  flex-direction: column;

  padding: 5px;

  align-items: center;
  justify-content: center;

  p {
    align-self: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & + div {
    margin-top: 15px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;

  flex: 1;
`;

export const Column = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;

  flex: 1;
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 400px;
`;

export const ChartContainer = styled.div``;
