import React from "react";
import ReactLoading from "react-loading";

import { LoadingContainer } from "./styles";

export const Loading: React.FC = () => {
  return (
    <LoadingContainer>
      <ReactLoading type="spinningBubbles" color="teal" />
    </LoadingContainer>
  );
};
