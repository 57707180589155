import React, { useEffect, useCallback } from "react";

import ReactLoading from "react-loading";
import api from "../../service/api";
import { ModalBackground } from "../ModalBackground";

import { IFrame, Container } from "./styles";

interface MapIframeProps {
  url: string;
  password: string;
  handleCloseIframe(): void;
}

interface IQueueResponse {
  created_at: Date;
  doc_name: string;
  done: boolean;
  password: string;
  updated_at: Date;
}

export const MapIframe: React.FC<MapIframeProps> = ({
  url,
  password,
  handleCloseIframe,
}) => {
  const getDocument = useCallback(
    async (fileName: string) => {
      try {
        setTimeout(async () => {
          const resume = await api.get(`/documents/itr/resume/${fileName}`, {
            responseType: "blob",
          });

          api.delete(`/documents/itr/resume/${fileName}`);

          const resumeBloc = new Blob([resume.data], {
            type: "application/pdf",
          });

          saveAs(resumeBloc, `resumo-${Date.now()}.pdf`);
        }, 1000);
      } finally {
        handleCloseIframe();
      }
    },
    [handleCloseIframe]
  );

  const fetchQueueStatus = useCallback(async () => {
    const { data }: { data: IQueueResponse } = await api.get(
      `documents/queue/${password}`
    );

    if (!data.done) {
      setTimeout(() => {
        fetchQueueStatus();
      }, 500);
    } else {
      await getDocument(data.doc_name);
    }
  }, [password, getDocument]);

  useEffect(() => {
    fetchQueueStatus();
  }, [fetchQueueStatus]);

  return (
    <ModalBackground>
      <Container>
        <div>
          <h1>
            Aguarde um momento o resumo desta propriedade está sendo gerado.
          </h1>
          <ReactLoading type="spinningBubbles" color="teal" />
        </div>
        <IFrame src={url} title="map" />
      </Container>
    </ModalBackground>
  );
};
