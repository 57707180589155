import React from "react";
import { FaRegEdit } from "react-icons/fa";
import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import { ActionButton } from "../ActionButton";

import Request from "../dto";

const EditPropertie: React.FC<Omit<Request, "existingValues">> = ({ id }) => {
  return (
    <Tooltip title="Editar">
      <Link to={`/propertie/${id}`}>
        <ActionButton>
          <FaRegEdit />
        </ActionButton>
      </Link>
    </Tooltip>
  );
};

export default EditPropertie;
