export default (content: { [key: string]: string }[]): boolean => {
  let isEmpty = true;
  if (content.length !== 1) {
    return false;
  }

  const keys = Object.keys(content[0]);

  keys.forEach((key) => {
    if (content[0][key] !== "") isEmpty = false;
  });

  return isEmpty;
};
