import styled from "styled-components";

export const Container = styled.div`
  width: 700px;

  padding: 15px;

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 3px;

  box-shadow: 0px 0px 15px -5px #666;

  div {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    h1 {
      margin-bottom: 10px;
    }

    svg {
      margin-bottom: 10px;
    }
  }
`;

export const IFrame = styled.iframe`
  border: none;
  width: 100%;
  height: 300px;
  overflow: hide;
`;
