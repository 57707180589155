/* eslint-disable react/prop-types */
import { createContext, useCallback, useContext, useState } from "react";

import api from "../service/api";

export interface SignInCredentials {
  email: string;
  password: string;
}

export interface SignInResponse {
  token: string;
  userName: string;
}

interface AuthContextData {
  user: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<SignInResponse>(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, userName: JSON.parse(user) };
    }

    return {} as SignInResponse;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    const signInCredentials: SignInCredentials = {
      email,
      password,
    };

    const { data }: { data: SignInResponse } = await api.post(
      "signin",
      signInCredentials
    );

    const { token, userName } = data;

    localStorage.setItem("email", email);

    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(userName)); // salvar o objeto do

    api.defaults.headers.authorization = `Bearer ${token}`;

    setUserData({
      token,
      userName,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    
    setUserData({} as SignInResponse);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: userData.userName,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
