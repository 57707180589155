import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  main {
    width: 50%;
    height: 50%;
    box-shadow: 0px 0px 15px -5px #555;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .textos {
      margin-top: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h1 {
        margin-bottom: 10px;
      }
    }

    .buttons {
      margin-bottom: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 75%;
      height: 30%;

      div {
        width: 100%;
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          background-color: #fff;
          width: 48%;
          height: 100%;
          border: 0.5px solid #26a69a;
          border-radius: 10px;
          transition: background-color ease-in-out 0.5s;
          align-items: center;
          justify-content: center;
          display: flex;
          text-decoration: none;
          color: #333;

          &:hover {
            background: #009688;
            color: #fff;
          }
        }

        button {
          width: 48%;
          height: 100%;
          background: #fff;
          border: 0.5px solid #26a69a;
          border-radius: 10px;
          transition: background-color ease-in-out 0.5s;

          &:hover {
            background: #009688;
            color: #fff;
          }
        }
      }
    }
  }
`;
