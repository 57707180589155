import styled from "styled-components";

export const InfoProvider = styled.div`
  margin: 10px 15px;
  padding: 25px;
  border-radius: 3.5px;
  box-shadow: 0px 0px 15px -5px #666;

  & > div {
    margin-top: 25px;
    grid-gap: 15px;
    display: grid;
    grid-template-columns: repeat(4, auto);

    & > div {
      /* background-color: black; */
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }
`;

export const PropertieInfo = styled.div`
  display: flex;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  height: 65px;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  -webkit-box-shadow: 0px -3px 7px -2px #333;
  box-shadow: 0px -3px 10px -2px #999;
`;

export const Button = styled.button`
  margin-left: auto;
  max-width: 185px;
  min-width: 175px;
  min-height: 45px;
  max-height: 50px;
  border: 1px solid #1a8080;
  color: #1a8080;
  background: #fff;
  margin-right: 133px;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #1a8080;
    color: #fff;
    cursor: pointer;
  }
`;
