/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import { Input } from "../../../../../components";
import { InfoProvider } from "../styles";

export const ContactFields: React.FC = () => {
  return (
    <InfoProvider>
      <h2>Contato</h2>
      <div>
        <div>
          <label>Telefone</label>
          <Input name="Telefone" type="number" maxLength={20} />
        </div>
        <div>
          <label>E-mail</label>
          <Input name="Email" maxLength={50} />
        </div>
      </div>
    </InfoProvider>
  );
};
