/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import api from "../service/api";
import { showToast } from "../components/CustomToast";

import history from "../routes/History";

const AxiosInterceptor = () => {
  api.interceptors.response.use(
    (rsp) => {
      const { status, data } = rsp;

      if (status === 201) {
        showToast({
          type: "success",
          message: data.message,
        });
      }
      return rsp;
    },
    (error) => {
      if (error.message === "Network Error" && !error.response) {
        showToast({
          type: "error",
          message: "Erro de conexão, confira sua conexão com a internet.",
        });

        return Promise.reject(error);
      }

      const { status, data } = error.response;

      if (status >= 400 && status < 500) {
        const messageError = data.message;

        if (status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          history.push("/");
          window.location.reload();
        }

        showToast({
          type: "warn",
          message: `${messageError}`,
        });
      }

      if (status >= 500 && status < 600) {
        showToast({ type: "error", message: "Internal server error" });
      }

      return Promise.reject(error);
    }
  );
};

export default AxiosInterceptor;
