import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContentContainer = styled.aside`
  position: absolute;
  width: calc(100% - 120px);
  color: #333;
  margin-left: 120px;
`;
export const StyledLink = styled(Link)`
  color: #008080;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
export const MenuContainer = styled.section`
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;
  border-radius: 2px;
  box-shadow: inset -10px 0px 22px -16px rgba(0, 0, 0, 0.4);
  overflow: auto;
  width: 120px;

  a {
    text-decoration: none;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
  }
  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 0.85em;
    height: 64px;
    max-width: 120px;
    min-width: 120px;
    padding: 15px;
    transition: all 0.3s ease-in;

    &:hover {
      color: #008080;
      background: #fff;
    }
    & + button {
      margin-top: 10px;
    }
    span {
      display: none;
    }

    svg {
      color: #aaaaaa;
      font-size: 26px;
    }

    &:hover span {
      font-size: 1em;
      margin-top: 5px;
      display: block;
      color: #008080;
    }

    &:hover svg {
      display: block;
      color: #008080;
    }

`;

export const Logo = styled.div`
  img {
    height: 45px;
  }
`;

export const HeaderComponent = styled.header`
  display: flex;

  justify-content: space-between;

  align-items: flex-start;

  margin: 0 20px 0 20px;

  div {
    display: flex;
    flex-direction: row;

    align-items: center;

    button {
      color: teal;

      font-size: 28px;
      margin: 15px;

      p {
        display: none;

        position: absolute;

        margin-top: 10px;

        font-size: 15px;
      }

      &:hover p {
        display: block;
      }
    }
  }
`;

export const LogoStyle = styled.div`
  display: flex;
  align-items: flex-start;
  img {
    height: 45px;
  }
`;

export const ButtonHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 64px;
  max-width: 120px;
  min-width: 120px;
  transition: all 0.3s ease-in;

  p {
    color: #333;
    font-size: 12.8px;
    margin-top: 10px;
  }

  svg {
    color: #aaaaaa;
    font-size: 25px;
  }
  &:hover {
    color: #008080;
    background: #fff;
  }
`;
