import React, { useCallback, useRef, useState } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";

import { Input, showToast } from "../../../../components";
import { Container, Button } from "./styles";

import yupValidation from "../../../../utils/yupValidation";
import api from "../../../../service/api";

interface FormPassword {
  id: string;
  password: string;
}
interface FormData {
  id: string;
  newPassword: string;
  confirmNewPassword: string;
}
const schemaNewPassword = Yup.object().shape({
  newPassword: Yup.string().required("Nova Senha obrigatória"),
  confirmNewPassword: Yup.string().required(
    "Confirmação da nova Senha obrigatória"
  ),
});
const schemaPassword = Yup.object().shape({
  password: Yup.string().required("Senha obrigatória"),
});

const ModificarSenha: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isAuth, setIsAuth] = useState<boolean>();

  const handleVerifyPassword = useCallback(
    async ({ id, password }: FormPassword) => {
      const formPassword: FormPassword = {
        id,
        password,
      };
      try {
        await schemaPassword.validate(
          { password },
          {
            abortEarly: false,
          }
        );
        const myId = localStorage.getItem("id");
        if (myId != null) {
          formPassword.id = myId;
        }
        await api.post("/pages/checkPassword", formPassword);
        showToast({
          type: "sucess",
          message: `Senha confere`,
        });
        setIsAuth(true);
      } catch (err) {
        const validationErrors = yupValidation(err);

        formRef.current?.setErrors(validationErrors);
      }
    },
    []
  );

  const handleSubmitNewPassword = useCallback(async ({ // password }: FormData
    id, newPassword, confirmNewPassword }: FormData) => {
    const formData: FormData = {
      id,
      newPassword,
      confirmNewPassword,
    };

    try {
      const myId = localStorage.getItem("id");
      if (myId != null) {
        formData.id = myId;
      }

      await schemaNewPassword.validate(
        { newPassword, confirmNewPassword },
        { abortEarly: false }
      );
      if (newPassword !== confirmNewPassword) {
        showToast({
          type: "error",
          message: `Senhas não são iguais`,
        });
      }
      await api.post("/pages/modifyPassword", formData);
      showToast({
        type: "sucess",
        message: `Senha alterada com sucesso`,
      });
    } catch (err) {
      const validationErrors = yupValidation(err);

      formRef.current?.setErrors(validationErrors);
    }
  }, []);

  return (
    <>
      <hr />
      <Container>
        {!isAuth && (
          <Form ref={formRef} onSubmit={handleVerifyPassword}>
            <Input name="password" placeholder="Digite sua senha atual" />
            <Button type="submit">Verificar</Button>
          </Form>
        )}
        {isAuth && (
          <Form ref={formRef} onSubmit={handleSubmitNewPassword}>
            <Input name="newPassword" placeholder="Digite sua nova senha" />
            <Input
              name="confirmNewPassword"
              placeholder="Confirme a sua nova senha"
            />
            <Button type="submit">Salvar</Button>
          </Form>
        )}
      </Container>
    </>
  );
};

export default ModificarSenha;
