/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import { Form } from "@unform/web";
import { Scope, FormHandles } from "@unform/core";

import { ModalLoading, showToast } from "../../../../components";
import api, { url } from "../../../../service/api";

import { Button, Footer } from "./styles";

import {
  AddresFields,
  ContactFields,
  PropertieFields,
  CharacteristicsFields,
  ArchivesFields,
  TerraNuaFields,
  PropertieAreaFields,
} from "./components";

export const CreatePropertie: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params: { id: string } = useParams();

  const [loading, setLoading] = useState<boolean>(false);

  const removeInvalidFiels = (data: { [key: string]: string }) => {
    const keys = Object.keys(data);

    const validFields: { [key: string]: string } = {};

    keys.forEach((key) => {
      if (data[key] !== "") {
        validFields[key] = data[key];
      }
    });

    return validFields;
  };

  const handleCharacAnswers = useCallback(
    (data: { [key: string]: string }, propertieId: number) => {
      const validFields = removeInvalidFiels(data);

      const characQuestions = Object.keys(validFields);

      return characQuestions.map((question) => {
        const [desc, id] = question.split("-");

        return {
          Pergunta: id,
          Propriedade: propertieId,
          "Descrição pergunta": desc,
          Resposta: data[question],
        };
      });
    },
    []
  );

  const handleUpload = useCallback(async (data: any, propId: number) => {
    const form = new FormData();

    form.append("file", data);
    const uploadResponse = await api.post("/uploads", form);

    const fileName = uploadResponse.data.filename;

    return {
      Descrição: fileName,
      Arquivo: `${url}/uploads/${fileName}`,
      Propriedade: propId,
    };
  }, []);

  const handleTerraNua = useCallback((data: any, propertieId: number) => {
    const validFields = removeInvalidFiels(data);

    const keys = Object.keys(validFields);

    const response: { terraNuaTypeId: number; area: number }[] = [];

    keys.forEach((key) => {
      response.push({
        terraNuaTypeId: parseInt(key, 10),
        area: parseFloat(data[key]),
      });
    });

    return { propertieId, values: response };
  }, []);

  const handlePropertieOwners = useCallback(
    (owners: number[], propertieId: number) => {
      return owners.map((owner) => ({
        Propriedade: propertieId,
        Proprietário: owner,
      }));
    },
    []
  );

  const handleCreate = useCallback(
    async (data: { [key: string]: any }) => {
      const { properties, questions, archives, terraNua } = data;

      const { prop_nome_dono, ...propertieData } = properties;

      const owners: number[] = prop_nome_dono;

      const validPropertiesValues = removeInvalidFiels(propertieData);
      // console.log(validPropertiesValues.NIRF);
      const t = window.location.search;
      const tmp = t.split("=");
      const newNirf = tmp[1];
      // console.log(newNirf);

      // api.post
      const addNirf = {
        id: newNirf,
        nirf: parseInt(validPropertiesValues.NIRF, 10),
      };
      // console.log(addNirf);
      api.post("pages/add-nirf", addNirf);
      const propertieResponse = await api.post(
        "pages/properties",
        validPropertiesValues
      );

      const propertie = propertieResponse.data;

      const propertiesPersonPayload = handlePropertieOwners(
        owners,
        propertie.id
      );

      api.post("pages/propertiesPerson", propertiesPersonPayload);

      const validCharacAnswers = handleCharacAnswers(questions, propertie.id);

      await api.post(
        "pages/propertiesCharacteristicsAnswers",
        validCharacAnswers
      );

      const validTerraNua = handleTerraNua(terraNua, propertie.id);

      await api.post("pages/terraNuaProperties", validTerraNua);

      if (archives.prop_anexos) {
        const validUpload = await handleUpload(
          archives.prop_anexos,
          parseInt(params.id, 10)
        );

        await api.post("/pages/propertiesPictures", validUpload);
      }

      showToast({
        type: "success",
        message: "Propriedade cadastrada com sucesso.",
      });
    },
    [
      handleCharacAnswers,
      handleUpload,
      handleTerraNua,
      handlePropertieOwners,
      params,
    ]
  );

  const handleUpdate = useCallback(
    async (data: { [key: string]: any }) => {
      const { properties, questions, archives, terraNua } = data;

      const { prop_nome_dono, ...propertieData } = properties;

      const owners: number[] = prop_nome_dono;

      const validPropertiesValues = removeInvalidFiels(propertieData);

      validPropertiesValues.id = params.id;

      await api.put("pages/properties", validPropertiesValues);

      if (owners.length > 0) {
        const propertiesPersonPayload = handlePropertieOwners(
          owners,
          parseInt(validPropertiesValues.id, 10)
        );

        await api.put("pages/propertiesPerson", propertiesPersonPayload);
      }

      const validTerraNua = handleTerraNua(terraNua, parseInt(params.id, 10));

      await api.put("properties/terraNua", validTerraNua);

      const validCharacAnswers = handleCharacAnswers(
        questions,
        parseInt(params.id, 10)
      );

      await api.put("properties/characteristics", validCharacAnswers);

      if (archives.prop_anexos) {
        const validUpload = await handleUpload(
          archives.prop_anexos,
          parseInt(params.id, 10)
        );

        await api.post("/pages/propertiesPictures", validUpload);
      }

      showToast({
        type: "success",
        message: "Propriedade atualizada com sucesso.",
      });
    },
    [
      handleCharacAnswers,
      handleUpload,
      params,
      handleTerraNua,
      handlePropertieOwners,
    ]
  );

  const handleSubmit = useCallback(
    async (data: { [key: string]: any }) => {
      try {
        setLoading(true);

        if (params.id) {
          await handleUpdate(data);
        } else {
          await handleCreate(data);
        }
      } finally {
        setLoading(false);
      }
    },
    [handleUpdate, handleCreate, params]
  );

  return (
    <>
      {loading && <ModalLoading />}
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Scope path="properties">
          <PropertieFields id={params.id} formRef={formRef} />
          <AddresFields />
          <ContactFields />
        </Scope>

        <Scope path="terraNua">
          <TerraNuaFields id={params.id} formRef={formRef} />
        </Scope>

        <Scope path="questions">
          <CharacteristicsFields id={params.id} formRef={formRef} />
        </Scope>

        <Scope path="archives">
          <ArchivesFields id={params.id} />
        </Scope>

        <Scope path="properties">
          <PropertieAreaFields />
        </Scope>

        <Footer>
          <Button type="submit">{params.id ? "ATUALIZAR" : "CADASTRAR"}</Button>
        </Footer>
      </Form>
    </>
  );
};
