import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 64px;
  max-width: 120px;
  min-width: 120px;
  transition: all 0.3s ease-in;

  p {
    color: #333;
    font-size: 12.8px;
    margin-top: 10px;
  }

  svg {
    color: #aaaaaa;
    font-size: 25px;
  }
  &:hover {
    color: #008080;
    background: #fff;

    p,
    svg {
      color: #008080;
    }
  }
`;
