/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import { Select } from "../../../../../../components";
import { InfoProvider } from "../../styles";

export const PropertieAreaFields: React.FC = () => {
  return (
    <InfoProvider style={{ marginBottom: "70px" }}>
      <h2>Área de propriedade</h2>
      <div>
        <div>
          <label htmlFor="Demarcado">Propriedade Demarcada</label>
          <Select
            isDisabled
            name="Demarcado"
            defaultValue={{ label: "Sim", value: "Sim" }}
            options={[
              {
                label: "Sim",
                value: "Sim",
              },
              {
                label: "Não",
                value: "Não",
              },
            ]}
          />
        </div>
      </div>
    </InfoProvider>
  );
};
