/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useState, RefObject } from "react";
import { FormHandles } from "@unform/core";

import verifyIfContentIsEmpty from "../../../../../utils/verifyIfContentIsEmpty";
import api from "../../../../../service/api";
import { Input, Loading } from "../../../../../components";
import { InfoProvider } from "../styles";

interface ITypes {
  id: number;
  Descrição: string;
  Valor: number;
  Situação: string;
  Cor: string;
}

interface TerraNuaFieldsProps {
  id: string | undefined;
  formRef: RefObject<FormHandles>;
}

export const TerraNuaFields: React.FC<TerraNuaFieldsProps> = ({
  id,
  formRef,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<ITypes[]>();

  const fetchTerraNuaTypes = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get(
        "/pages/terraNuaType?table=false&orderByAsc=Ordem"
      );

      setTypes(data.content);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTerraNuaTypes();
  }, [fetchTerraNuaTypes]);

  const fetchResponses = useCallback(async () => {
    const { data } = await api.get(
      `pages/terraNuaProperties?table=false&Propriedade=${id}`
    );

    const isEmpty = verifyIfContentIsEmpty(data.content);

    if (!isEmpty) {
      const values: { [key: string]: string } = {};

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.content.forEach((element: any) => {
        values[`${element.Tipo}`] = element["Área"];
      });

      formRef.current?.setData({ terraNua: values });
    }
  }, [formRef, id]);

  useEffect(() => {
    if (id && !loading) {
      fetchResponses();
    }
  }, [fetchResponses, loading, id]);

  if (loading) {
    return <Loading />;
  }
  return (
    <InfoProvider>
      <h2>Valores de terra nua</h2>
      <div>
        {types &&
          types.map((element) => (
            <div>
              <label style={{ color: element.Cor }}>
                <p>{element.Descrição}</p>
              </label>

              <Input
                name={element.id.toString()}
                key={element.id}
                type="number"
                step="any"
              />
            </div>
          ))}
      </div>
    </InfoProvider>
  );
};
