import React, { useEffect, useState, useCallback } from "react";

import api from "../../../service/api";
import { Title, Charts, OLMap, Container } from "../../../components";

import {
  IPropertiesByArea,
  IDelimitedProperties,
  IResponse,
} from "./interfaces";

import { ChartOptions } from "./chartOptions";
import { Table } from "./components";
import { MapContainer, ChartContainer, CardContainer, Row } from "./styles";

const Dashboard: React.FC = () => {
  const [delimited, setDelimeted] = useState<IDelimitedProperties>(
    {} as IDelimitedProperties
  );
  const [groupedByArea, setGroupedByArea] = useState<IPropertiesByArea>(
    {} as IPropertiesByArea
  );

  const fetchPropertiesData = useCallback(async () => {
    const { data }: IResponse = await api.get("/properties/statistics");

    const { delimetedProperties, propertiesByArea } = data;

    const [delimetedPropertiesInfo, propertiesQuantity] = delimetedProperties;

    setDelimeted({
      properties: delimetedPropertiesInfo,
      quantity: propertiesQuantity,
    });
    setGroupedByArea(propertiesByArea);
  }, []);

  useEffect(() => {
    fetchPropertiesData();
  }, [fetchPropertiesData]);

  const handleChartOptions = (isSunburst?: boolean) => {
    if (isSunburst) {
      return new ChartOptions(groupedByArea).sunburstChartOptions();
    }

    return new ChartOptions(groupedByArea).pieChartOptions();
  };

  return (
    <Container>
      <Title title="Dashboard" />
      <CardContainer>
        <p>
          Propriedades cadastradas com área demarcada: {delimited.quantity}.
        </p>
        <MapContainer>
          <OLMap />
        </MapContainer>
      </CardContainer>
      <CardContainer>
        <p>Quantidade de propriedades por hectare.</p>
        <Row>
          <ChartContainer>
            <Charts options={handleChartOptions(true)} isSunBurst />
          </ChartContainer>
          <Table data={groupedByArea} />
        </Row>
      </CardContainer>
      <CardContainer>
        <p>Propriedades demarcadas x Propriedades não demarcadas.</p>
        <Charts options={handleChartOptions()} />
      </CardContainer>
    </Container>
  );
};

export default Dashboard;
