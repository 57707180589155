import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

	body {
		background-color: #ffffff;
		-webkit-font-smoothing: antialiased;
	}

	body, input, button, table {
		font: 0.95em 'Montserrat', sans-serif;
	}

	button {
		border: none;
		background-color: rgba(0,0,0,0);

		color: #333;

		cursor:pointer;
	}
`;
