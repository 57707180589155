import styled from "styled-components";

export const ImagePreviewContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-right: 10px;

  justify-content: space-around;

  & > div {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: space-between;
  }

  button {
    width: 100px;
    margin-top: 2px;
    padding: 5px;
  }
`;
