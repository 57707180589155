export default (body: string[][] | undefined): boolean => {
  let isEmpty = true;
  if (!body) {
    return true;
  }

  if (body.length !== 1) {
    return false;
  }

  body[0].forEach((element) => {
    if (element !== "") {
      isEmpty = false;
    }
  });

  return isEmpty;
};
