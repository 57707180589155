import React, { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FiHelpCircle, FiLogOut } from "react-icons/fi";
import * as fa from "react-icons/fa";
import * as si from "react-icons/si";

import { Link } from "react-router-dom";
import logoSemv from "../../assets/images/logoSemv.png";
import { useAuth } from "../../hooks/auth";

import {
  MenuContainer,
  Logo,
  ContentContainer,
  HeaderComponent,
  LogoStyle,
  StyledLink,
} from "./styles";

type Childrens = {
  menu: React.ReactNode;
  content: React.ReactNode;
};

const Frame: React.FC<Childrens> = ({ menu, content }) => {
  const [open, isOpen] = useState(false);

  const { signOut } = useAuth();

  function handleOpenMenu() {
    const menuBar = document.querySelector<HTMLElement>(".menu");
    const contentContainer = document.querySelector<HTMLElement>(
      ".content-container"
    );

    if (menuBar && contentContainer) {
      if (!open) {
        menuBar.style.marginLeft = "0";

        contentContainer.style.marginLeft = "120px";
        contentContainer.style.width = "calc(100% - 120px)";
      } else {
        menuBar.style.marginLeft = "-120px";

        contentContainer.style.marginLeft = "0";
        contentContainer.style.width = "100%";
      }
    }
  }

  function findViewCoords(mouseEvent: MouseEvent) {
    let xpos;

    if (mouseEvent) {
      xpos = mouseEvent.pageX - document.body.scrollLeft;
    }

    if (xpos && xpos < 20) {
      handleOpenMenu();
    }
  }

  useState(() => {
    const root = document.querySelector<HTMLElement>("#root");

    if (root) {
      root.onmousemove = findViewCoords;
    }
  });

  return (
    <>
      <MenuContainer className="menu">
        <Logo>
          <img src={logoSemv} alt="Logo" />
        </Logo>
        <div>{menu}</div>
        <div />
      </MenuContainer>
      <HeaderComponent>
        <LogoStyle>
          <button
            type="button"
            onClick={() => {
              isOpen(!open);
              handleOpenMenu();
            }}
          >
            <img src={logoSemv} alt="Logo" />
          </button>
        </LogoStyle>
        <div>
          <button type="button">
            <fa.FaRegFolderOpen />
            <p>abrir tarefa</p>
          </button>
          <button type="button">
            <FiHelpCircle />
            <p>ajuda</p>
          </button>
          <button type="button">
            <AiOutlineUser />
            <p>usuario</p>
          </button>
          <button type="button">
            <StyledLink to="/senha">
              <si.Si1Password />
              <p>modificar senha</p>
            </StyledLink>
          </button>
          <button type="button" onClick={() => signOut()}>
            <FiLogOut />
            <p>sair</p>
          </button>
        </div>
      </HeaderComponent>
      <ContentContainer>{content}</ContentContainer>
    </>
  );
};

export default Frame;
