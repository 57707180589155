import React, { HTMLProps } from "react";

type ActionButtonProps = HTMLProps<HTMLButtonElement>;

export const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  children,
}) => {
  return (
    <button
      type="button"
      style={{
        marginLeft: "5px",
        marginRight: "5px",
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
