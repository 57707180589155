/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, InputHTMLAttributes } from "react";
import { useField } from "@unform/core";
import { IconBaseProps } from "react-icons";
import { CheckBoxContainer } from "./styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    value: string;
    label: string;
    icon?: React.ComponentType<IconBaseProps>;
    actions?: string[];
    functionalButtons?: string[];
  }[];
  checkedOptions?: string[];
  doAfterChangeValue?(id: any, checked: boolean): void;
}
const CheckboxInput: React.FC<Props> = ({
  name,
  options,
  doAfterChangeValue,
  // checkedOptions,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) =>
        refs.filter((ref) => ref.checked).map((ref) => ref.value),
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach((ref) => {
          if (values && values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [fieldName, registerField, defaultValue]);

  const onChangeCheck = (e: any) => {
    if (doAfterChangeValue) {
      doAfterChangeValue(e.target.id, e.target.checked);
    }
  };
  const CreateCheckbox = (option: any, index: any) => {
    return (
      <label htmlFor={option.id} key={option.id}>
        <input
          type="checkbox"
          ref={(ref) => {
            inputRefs.current[index] = ref as HTMLInputElement;
          }}
          value={option.value}
          onChange={(e) => onChangeCheck(e)}
          id={option.id}
          {...rest}
        />
        <div>
          {option.icon && <option.icon size={20} color="teal" />}
          {option.label}
        </div>
      </label>
    );
  };
  return (
    <CheckBoxContainer>
      {options.map((option, index) => (
        <>{CreateCheckbox(option, index)}</>
      ))}
    </CheckBoxContainer>
  );
};
export default CheckboxInput;
