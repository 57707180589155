import React from "react";

import { ModalBackground } from "../ModalBackground";
import { Loading } from "../Loading";

export const ModalLoading: React.FC = () => {
  return (
    <ModalBackground>
      <div
        style={{
          backgroundColor: "rgba(255,255,255,0.5)",
          width: "100%",
          height: "100%",
        }}
      >
        <Loading />
      </div>
    </ModalBackground>
  );
};
