/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface TabPanelProps {
  children: React.ReactNode;
  index: any;
  value: any;
  className: any;
}

interface TabsStruct {
  nomeRota: string;
  rota?: string;
  tabContent?: React.ReactNode;
}

interface Request {
  tabs: TabsStruct[];
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
// theme.palette.background.paper
const useStyles = makeStyles(() => ({
  tabPanel: {
    width: "100%",
    height: "100%",
  },
  iframe: {
    width: "100%",
    minHeight: "65vh",
    maxHeight: "100%",
    border: "none",
  },
}));

const VerticalTabs: React.FC<Request> = ({ tabs }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        {tabs.map((tab, index) => (
          <Tab label={tab.nomeRota} {...a11yProps(index)} />
        ))}
      </Tabs>
      <div style={{ overflow: "auto", width: "100%" }}>
        {tabs.map((tab, index) => (
          <TabPanel value={value} index={index} className={classes.tabPanel}>
            {tab.tabContent ? (
              tab.tabContent
            ) : (
              <iframe
                src={`/consulta/tabs/${tab.rota}`}
                title="conteudo aba"
                className={classes.iframe}
              />
            )}
          </TabPanel>
        ))}
      </div>
    </>
  );
};

export default VerticalTabs;
