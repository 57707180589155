import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaRegSave } from "react-icons/fa";
import CheckboxInput from "../../../../../../components/Checkbox";
import { ButtonBuscar, Container } from "./styles";
import { useFields } from "../../../../../../hooks/fields";

interface Form {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
interface FieldsToSelectProps {
  fields: string[];
}
export const FieldsToSelect: React.FC<FieldsToSelectProps> = ({ fields }) => {
  const formRef = useRef<FormHandles>(null);
  const { setFieldsToSelect, saveFields } = useFields();
  const [path, setPath] = useState<string>(window.location.pathname);

  useEffect(() => {
    // const tmp = localStorage.getItem(path);
    setPath(window.location.pathname);
  }, []);

  const isChecked = useCallback((element: string) => {
    const tmp = localStorage.getItem(path);

    if (tmp) {
      const selected: string[] = tmp.split(",");
      const temp = selected[0].split("=")[1];
      selected.push(temp);
      return selected.includes(element);
    }
    return true;
  }, []);

  const handleSubmit = useCallback(
    (data: Form) => {
      const keys = Object.keys(data);

      const selectedFields: string[] = [];

      keys.forEach((key) => {
        if (data[key].length > 0) {
          selectedFields.push(`${data[key]}`);
        }
      });
      const stringfyValidFields = `&select=${selectedFields.join(",")}`;

      setFieldsToSelect(stringfyValidFields);
      saveFields(stringfyValidFields);
      localStorage.setItem(path, selectedFields.join(","));
    },
    [path, saveFields, setFieldsToSelect]
  );
  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          gridTemplateRows: "repeat(auto, 65px)",
        }}
        ref={formRef}
      >
        {fields.map((element) => (
          <CheckboxInput
            name={element}
            options={[
              {
                id: element,
                value: element,
                label: element,
              },
            ]}
            defaultChecked={isChecked(element)}
          />
        ))}
        <ButtonBuscar type="submit">
          <FaRegSave /> Aplicar
        </ButtonBuscar>
      </Form>
    </Container>
  );
};
