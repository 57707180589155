import React from "react";
import { Link, useHistory } from "react-router-dom";

import { Container } from "./styles";

const PageNotFound: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <main>
        <div className="textos">
          <h1>Error 404</h1>
          <span>Pagina nao encontrada.</span>
        </div>
        <div className="buttons">
          <span>Vamos redirecionar ?</span>
          <div>
            <Link to="/home">Ir para Home</Link>
            <button type="button" onClick={() => history.go(-1)}>
              Voltar para pagina anterior
            </button>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default PageNotFound;
