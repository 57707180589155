import styled from "styled-components";

export const Container = styled.div`
  h1 {
    margin-top: 20px;
    margin-left: 15px;
    margin-bottom: 25px;
    color: #333;
    font-size: 16.7px;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
  }
`;
