import React, { useState, useCallback, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import api from "../../service/api";

import { Cadastro } from "../../pages/App/DefaultPages";
import { ButtonPlus } from "./styles";

import {
  IDefaultFieldsContent,
  IDefaultPagesResponse,
} from "../../interfaces/IDefaultPages";

interface QuickRegisterProps {
  url: string;
}

const QuickRegister: React.FC<QuickRegisterProps> = ({ url }) => {
  const [register, setRegister] = useState(false);
  const [nomePagina, setNomePagina] = useState<string>("");
  const [registerFields, setRegisterFields] = useState<IDefaultFieldsContent[]>(
    []
  );

  const showCadastro = useCallback((state: boolean) => {
    setRegister(state);
  }, []);

  const connection = useCallback(async () => {
    const urlQuery = `/pages/${url}`;

    const response = await api.get(urlQuery);

    const { fields, pageName }: IDefaultPagesResponse = response.data;

    setRegisterFields(fields.registerFields);
    setNomePagina(pageName);
  }, [url]);

  useEffect(() => {
    connection();
  }, [connection]);

  return (
    <>
      <ButtonPlus type="button" onClick={() => showCadastro(true)}>
        <AiOutlinePlus />
      </ButtonPlus>

      {register ? (
        <Cadastro
          title={nomePagina}
          registerFields={registerFields}
          closeModal={showCadastro}
          type="register"
        />
      ) : null}
    </>
  );
};

export default QuickRegister;
