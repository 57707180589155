import styled from "styled-components";

interface ContainerInputsProps {
  numberOfColumns: number;
}

export const FiltersContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(18, 80, 80, 0.5);
  }
  form {
    width: 100%;
    height: 100%;

    align-items: center;
    justify-self: center;
  }
`;

export const ContainerInputs = styled.div<ContainerInputsProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.numberOfColumns}, 1fr);
  grid-template-rows: repeat(auto, 65px);
  grid-gap: 15px;
`;

export const BoxButton = styled.div`
  width: 100%;
  min-height: 50px;
  margin-top: 10px;

  button {
    float: right;
  }
`;

export const BoxFile = styled.div`
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  height: 100%;
`;
