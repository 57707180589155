import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../service/api";

import verifyIfBodyIsEmpty from "../../../../utils/verifyIfBodyIsEmpty";

import {
  useForceUpdate,
  usePagination,
  useFilters,
  useOrder,
  useFields,
} from "../../../../hooks";

import Cadastro from "../Cadastro";

import {
  IDefaultPagesResponse,
  IDefaultTabs,
  IDefaultFields,
  IDefaultContent,
} from "../../../../interfaces/IDefaultPages";

import { Table, Title, Fields, Reports, Loading } from "../../../../components";

import { buildUrl } from "./functions";
import { TopRightButtons, Footer, FieldsToSelect } from "./components";
import { FiltersContainer } from "./styles";

const Consulta: React.FC = () => {
  const params: { id: string; submenu: string } = useParams();
  const { currentPage, currentLimit } = usePagination();
  const { filters } = useFilters();
  const { fieldsToSelect } = useFields();
  const { orderBy } = useOrder();
  const { tick } = useForceUpdate();

  const [loading, setLoading] = useState<boolean>(false);

  const [aux, setAux] = useState<boolean>(false);
  const [_topRightButtons, setTopRightButtons] = useState<string[]>();
  const [nomePagina, setNomePagina] = useState<string>("");
  const [register, setRegister] = useState(false);
  const [relatorios, setRelatorios] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [pageFields, setPageFields] = useState<IDefaultFields>(
    {} as IDefaultFields
  );
  const [showFieldsToSelect, setShowFieldsToSelect] = useState<boolean>(false);
  const [numberOfPages_, setNumberOfPages] = useState<number>(0);

  const [tableContent, setTableContent] = useState<IDefaultContent>();
  const [quantity, setQuantity] = useState<number>(0);

  const [_tabs, setTabs] = useState<IDefaultTabs>();
  const [type, setType] = useState<"register" | "clone">("register");
  const [existingValues, setExistingValues] = useState<
    | {
        [key: string]: string | number;
      }
    | undefined
  >({});

  const handleFiltros = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const handleFieldsToSelect = useCallback(() => {
    setShowFieldsToSelect(!showFieldsToSelect);
  }, [showFieldsToSelect]);

  const handleReports = useCallback(() => {
    setRelatorios(!relatorios);
  }, [relatorios]);

  const handleRegister = useCallback((state: boolean) => {
    setRegister(state);
  }, []);

  const handleExistingValues = useCallback(
    (data: { [key: string]: string | number }, modalType = "register") => {
      setType(modalType);
      setExistingValues(data);
      handleRegister(true);
    },
    [handleRegister]
  );

  const fetchPage = useCallback(
    async (
      limit: number,
      page: number,
      currentFilters: string | undefined,
      currentOrderBy: string | undefined,
      currentFields: string | undefined
    ) => {
      try {
        setLoading(true);
        const url = buildUrl({
          submenu: params.submenu,
          page,
          limit,
          currentOrderBy,
          currentFilters,
          currentFields,
        });

        const { data }: { data: IDefaultPagesResponse } = await api.get(url);

        const {
          content,
          fields,
          pageName,
          quantityOfData,
          tabs,
          numberOfPages,
          topRightButtons,
        } = data;

        setTopRightButtons(topRightButtons);
        setTableContent(content);
        setNomePagina(pageName);
        setQuantity(quantityOfData);
        setTabs(tabs);
        setPageFields(fields);
        if (numberOfPages) setNumberOfPages(numberOfPages);
      } finally {
        setLoading(false);
      }
    },
    [params.submenu]
  );

  useEffect(() => {
    setAux(true);
  }, []);

  useEffect(() => {
    if (aux)
      fetchPage(currentLimit, currentPage, filters, orderBy, fieldsToSelect);
  }, [
    fetchPage,
    currentLimit,
    currentPage,
    filters,
    orderBy,
    tick,
    fieldsToSelect,
    aux,
  ]);

  return (
    <>
      <Title
        title={nomePagina}
        style={{ borderBottom: "1px solid #ccc", paddingLeft: "10px" }}
      />

      {_topRightButtons && (
        <TopRightButtons
          buttonsToShow={_topRightButtons}
          handleFiltros={handleFiltros}
          handleRegister={handleRegister}
          handleReports={handleReports}
          handleFields={handleFieldsToSelect}
          disableButtons={loading}
        />
      )}

      {showFilters && (
        <FiltersContainer>
          <Fields fields={pageFields.filterFields} type="filters" />
        </FiltersContainer>
      )}

      {relatorios && tableContent && <Reports table={tableContent.table} />}

      {showFieldsToSelect && (
        <FiltersContainer>
          <FieldsToSelect fields={pageFields.fields} />
        </FiltersContainer>
      )}

      {tableContent ? (
        <Table
          tbody={tableContent.table.tbody}
          tbuttons={tableContent.buttons}
          tuploads={tableContent.camposComUpload}
          thead={tableContent.table.thead}
          handleExistingValues={handleExistingValues}
        />
      ) : (
        <Loading />
      )}

      <Footer
        numberOfPages={numberOfPages_}
        quantityOfItems={quantity}
        quantityOfItemsDisplayed={
          verifyIfBodyIsEmpty(tableContent?.table.tbody)
            ? 0
            : tableContent?.table.tbody.length
        }
      />

      {register && (
        <Cadastro
          title={nomePagina}
          registerFields={pageFields.registerFields}
          closeModal={handleRegister}
          type={type}
          existingValues={existingValues}
          tabs={_tabs}
        />
      )}
    </>
  );
};

export default Consulta;
