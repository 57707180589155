import React, { useCallback, useState } from "react";

import { MainButton, showToast } from "../../../../../../../../components";

import api from "../../../../../../../../service/api";

import { ITerraNuaValues } from "../../types";
import { ConfirmWindow } from "../ConfimWindow";

interface RecalculateButtonProps {
  terraNuaValues: ITerraNuaValues[] | undefined;
  doAfterRecalculate(): void;
  doBeforeRecalculate(): void;
  propertieId: string;
}

export const RecalculateButton: React.FC<RecalculateButtonProps> = ({
  terraNuaValues,
  doAfterRecalculate,
  doBeforeRecalculate,
  propertieId,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const toggleDialog = useCallback(() => {
    setShowDialog(!showDialog);
  }, [showDialog]);

  const handleRecalculateTerraNuaValues = useCallback(async () => {
    toggleDialog();
    doBeforeRecalculate();

    try {
      if (terraNuaValues && terraNuaValues.length > 0) {
        const validValues = terraNuaValues.filter(
          (element) => element.Área === 0 || element.Área
        );

        const values = validValues.map((element) => {
          return {
            terraNuaTypeId: element.Tipo,
            area: element.Área,
          };
        });

        const terraNuaPayload = {
          propertieId,
          values,
        };

        await api.put("properties/terraNua", terraNuaPayload);

        showToast({
          type: "success",
          message: "Valores recalculados com sucesso.",
        });
      }
    } finally {
      doAfterRecalculate();
    }
  }, [
    toggleDialog,
    doBeforeRecalculate,
    doAfterRecalculate,
    propertieId,
    terraNuaValues,
  ]);

  return (
    <>
      {showDialog && (
        <ConfirmWindow
          doAfterCancel={toggleDialog}
          doAfterConfirm={handleRecalculateTerraNuaValues}
          text="Recalcular irá substituir os valores existentes e consequentemente a operação não poderá ser desfeita. Gostaria de continuar?"
        />
      )}
      <MainButton
        type="button"
        style={{ marginTop: "10px" }}
        onClick={toggleDialog}
      >
        Recalcular
      </MainButton>
    </>
  );
};
