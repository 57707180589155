import styled from "styled-components";

export const SubMenu = styled.div`
  margin-left: 25px;
`;

export const Menu = styled.div`
  margin: 5px;
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
`;
// grid-template-columns: repeat(2, 500px 1fr) 1px;
export const Modules = styled.div`
  display: grid;
  grid-template-column: 500px;
`;

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
  width: 100%;
`;
