interface buildUrlProps {
  submenu: string;
  limit: number;
  currentFilters: string | undefined;
  page: number;
  currentOrderBy: string | undefined;
  currentFields: string | undefined;
}

export const buildUrl = ({
  submenu,
  limit,
  currentFilters,
  page,
  currentOrderBy,
  currentFields,
}: buildUrlProps): string => {
  const orderBy = currentOrderBy; // || "orderByAsc=id";

  let url = `/pages/${submenu}?&limit=${limit}&offset=${
    limit * page - limit
  }&${orderBy}`;

  if (currentFilters) {
    url += `&${currentFilters}`;
  }
  if (currentFields) {
    url += `${currentFields}`;
  }

  return url;
};
