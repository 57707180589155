import { createContext, useContext, useState, useCallback } from "react";

interface LayersContextData {
  selectedLayers: string[];
  saveSelectedLayers(layers: string[]): void;
  removeLayer(layer: string): void;
}

const LayersContext = createContext<LayersContextData>({} as LayersContextData);

const LayersProvider: React.FC = ({ children }) => {
  const [selectedLayers, setSelectedLayers] = useState<string[]>(() => {
    const layers = localStorage.getItem("dashboard-layers");

    if (layers) {
      return JSON.parse(layers);
    }

    return [];
  });

  const saveSelectedLayers = useCallback(
    (layers: string[]) => {
      const tmp = [...selectedLayers, ...layers];

      localStorage.setItem("dashboard-layers", JSON.stringify(tmp));

      setSelectedLayers(tmp);
    },
    [selectedLayers]
  );

  const removeLayer = useCallback(
    (layer: string) => {
      const layerIndex = selectedLayers.findIndex((x) => x === layer);

      if (layerIndex >= 0) {
        const tmp = [...selectedLayers];

        tmp.splice(layerIndex, 1);

        localStorage.setItem("dashboard-layers", JSON.stringify(tmp));

        setSelectedLayers(tmp);
      }
    },
    [selectedLayers]
  );

  return (
    <LayersContext.Provider
      value={{ selectedLayers, saveSelectedLayers, removeLayer }}
    >
      {children}
    </LayersContext.Provider>
  );
};

function useLayers(): LayersContextData {
  const context = useContext(LayersContext);

  if (!context) {
    throw new Error("useLayers must be used within an LayersProvider");
  }

  return context;
}

export { LayersProvider, useLayers };
