import Dashboard from "../Dashboard";
import Menus from "../Menus";
import Consulta from "../DefaultPages/Consulta";
import { ModificarSenha } from "../DefaultPages";
import { PropertiesView, CreatePropertie } from "../CustomPages";
import LiberacaoAcesso from "../LiberacaoAcesso";

export const routes = [
  {
    component: Dashboard,
    exact: true,
    path: "/dashboard",
  },
  {
    component: ModificarSenha, // TELA QUE VOCE IRA CRIAR
    exact: true,
    path: "/senha", // NOME DA ROTA QUE VOCE DESEJA
  },
  {
    component: LiberacaoAcesso,
    exact: true,
    path: "/modulos/:id/permissions",
  },
  {
    component: PropertiesView,
    exact: true,
    path: "/modulos/:id/:submenu/:itemId",
  },
  {
    component: Consulta,
    exact: true,
    path: "/modulos/:id/:submenu",
  },
  {
    component: Menus,
    exact: true,
    path: "/modulos/:id",
  },
  {
    component: CreatePropertie,
    exact: true,
    path: "/propertie/:id",
  },
  {
    component: CreatePropertie,
    exact: true,
    path: "/propertie",
  },
];
