import React, { useCallback, useEffect, useState } from "react";

import { IoClose } from "react-icons/io5";
import {
  IDefaultFieldsContent,
  IDefaultTabs,
  ITabsStrucure,
} from "../../../../interfaces/IDefaultPages";

import { Modal, FieldsContainer, CloseButton } from "./styles";

import {
  Title,
  Fields,
  showToast,
  VerticalTabs,
  ModalBackground,
} from "../../../../components";
import { useForceUpdate } from "../../../../hooks";

interface CadastroProps {
  registerFields: IDefaultFieldsContent[];
  closeModal(state: boolean): void;
  type: "register" | "clone" | "edit";
  existingValues?: { [key: string]: number | string };
  tabs?: IDefaultTabs;
  title: string;
}

const Cadastro: React.FC<CadastroProps> = ({
  registerFields,
  closeModal,
  type,
  existingValues,
  title,
  tabs,
}) => {
  const { update } = useForceUpdate();
  const [tabRoutes, setTabRoutes] = useState<ITabsStrucure[]>([]);

  const handleCloseModal = useCallback(() => {
    closeModal(false);
  }, [closeModal]);

  const doAfterRegister = useCallback(() => {
    update();
    const tmp =
      type === "clone" || type === "register"
        ? "cadastrado"
        : `com código ${existingValues?.id} atualizado`;

    const message = `Registro ${tmp} com sucesso`;

    showToast({
      type: "success",
      message,
    });

    handleCloseModal();
  }, [type, existingValues, handleCloseModal, update]);

  const handleTabs = useCallback(() => {
    if (tabs) {
      setTabRoutes(tabs.estruturaAbas);
    }
  }, [tabs]);

  useEffect(() => {
    handleTabs();
  }, [handleTabs]);

  if (tabs?.ativo) {
    return (
      <ModalBackground>
        <Modal>
          <div style={{ width: "100%" }}>
            <CloseButton
              type="button"
              onClick={() => closeModal(false)}
              style={{ float: "right" }}
            >
              <IoClose />
            </CloseButton>
          </div>
          <VerticalTabs
            tabs={[
              {
                nomeRota: title,
                tabContent: (
                  <Fields
                    type={type}
                    fields={registerFields}
                    existingValues={existingValues}
                    doAfterCancel={() => closeModal(false)}
                  />
                ),
              },
              ...tabRoutes,
            ]}
          />
        </Modal>
      </ModalBackground>
    );
  }

  return (
    <ModalBackground>
      <Modal>
        <header>
          <div>
            <Title title={title} />
          </div>
          <div>
            <CloseButton type="button" onClick={() => closeModal(false)}>
              <IoClose />
            </CloseButton>
          </div>
        </header>
        <FieldsContainer>
          <Fields
            type={type}
            fields={registerFields}
            existingValues={existingValues}
            doAfterRegister={doAfterRegister}
            doAfterCancel={() => closeModal(false)}
            numberOfColumns={3}
          />
        </FieldsContainer>
      </Modal>
    </ModalBackground>
  );
};

export default Cadastro;
