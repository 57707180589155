import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface FieldsContextData {
  fieldsToSelect: string | undefined;
  setFieldsToSelect(currentUrl: string): void;
  saveFields(fieldsToSelect: string): void;
}

const FieldsContext = createContext<FieldsContextData>({} as FieldsContextData);

const FieldsProvider: React.FC = ({ children }) => {
  const [fieldsToSelect, setFieldsToSelect] = useState<string>(() => {
    const hasField = localStorage.getItem(
      `fieldsToSelect-${window.location.pathname}`
    );
    if (hasField) return hasField;

    return "";
  });

  const saveFields = useCallback((hasField: string) => {
    const tmp = hasField;

    localStorage.setItem(`fieldsToSelect-${window.location.pathname}`, tmp);

    setFieldsToSelect(tmp);
  }, []);

  useEffect(() => {
    const hasField = localStorage.getItem(
      `fieldsToSelect-${window.location.pathname}`
    );

    if (hasField) setFieldsToSelect(hasField);
    else setFieldsToSelect("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <FieldsContext.Provider
      value={{ fieldsToSelect, setFieldsToSelect, saveFields }}
    >
      {children}
    </FieldsContext.Provider>
  );
};

function useFields(): FieldsContextData {
  const context = useContext(FieldsContext);
  if (!context) {
    throw new Error("useFields must be used within an FieldsProvider");
  }

  return context;
}

export { FieldsProvider, useFields };
