import React, { useState } from "react";

import { useOrder } from "../../../../hooks/order";

interface THeadProps {
  label: string;
}

export const THead: React.FC<THeadProps> = ({ label }) => {
  const [byAsc, setByAsc] = useState(true);
  const { saveOrderBy } = useOrder();

  const handleOrderBy = () => {
    if (byAsc) {
      saveOrderBy(`orderByAsc=${label}`);
    } else {
      saveOrderBy(`orderByDesc=${label}`);
    }
    setByAsc(!byAsc);
  };

  return (
    <th>
      <button type="button" onClick={handleOrderBy}>
        {label}
      </button>
    </th>
  );
};
