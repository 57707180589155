import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface PaginationContextData {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentLimit: number;
  setCurrentLimit: React.Dispatch<React.SetStateAction<number>>;
  savePage(lastPage: number): void;
}

const PaginationContext = createContext<PaginationContextData>(
  {} as PaginationContextData
);

const PaginationProvider: React.FC = ({ children }) => {
  const [currentLimit, setCurrentLimit] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const hasPage = localStorage.getItem(
      `lastPage-${window.location.pathname}`
    );
    if (hasPage) return parseInt(hasPage, 10);

    return 1;
  });

  const savePage = useCallback((lastPage: number) => {
    const tmp = lastPage;

    localStorage.setItem(
      `lastPage-${window.location.pathname}`,
      tmp.toString()
    );

    setCurrentPage(tmp);
  }, []);

  useEffect(() => {
    const hasPage = localStorage.getItem(
      `lastPage-${window.location.pathname}`
    );
    if (hasPage) setCurrentPage(parseInt(hasPage, 10));
    else setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <PaginationContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        currentLimit,
        setCurrentLimit,
        savePage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

function usePagination(): PaginationContextData {
  const context = useContext(PaginationContext);

  if (!context) {
    throw new Error("usePagination must be used within an PaginationProvider");
  }

  return context;
}

export { PaginationProvider, usePagination };
