import React, { useCallback, useState } from "react";
import { FaRegListAlt } from "react-icons/fa";
import Tooltip from "@material-ui/core/Tooltip";
import ReactLoading from "react-loading";

import api, { url } from "../../../service/api";

import { ActionButton } from "../ActionButton";
import Request from "../dto";

import { MapIframe } from "../../MapIframe";

import { LoadingContainer } from "./styles";

interface IIframe {
  url: string;
  password: string;
}

const ITRReport: React.FC<Omit<Request, "existingValues">> = ({ id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [iframe, setIframe] = useState<IIframe>();

  const handleCloseIFrame = useCallback(() => {
    setIframe(undefined);
  }, []);

  const handleGenerateReport = useCallback(async () => {
    try {
      setLoading(true);

      setTimeout(async () => {
        const { data } = await api.post("documents/queue");

        const { password } = data;

        setIframe({
          url: `${url}/documents/itr/resume/jaguariaiva/${id}/${password}`,
          password,
        });
      }, 1000);
    } finally {
      setLoading(false);
    }
  }, [id]);

  if (loading) {
    return (
      <LoadingContainer>
        <ReactLoading type="spinningBubbles" color="teal" />
      </LoadingContainer>
    );
  }

  if (iframe) {
    return (
      <MapIframe
        url={iframe.url}
        password={iframe.password}
        handleCloseIframe={handleCloseIFrame}
      />
    );
  }

  return (
    <Tooltip title="Resumo">
      <div>
        <ActionButton onClick={handleGenerateReport}>
          <FaRegListAlt />
        </ActionButton>
      </div>
    </Tooltip>
  );
};

export default ITRReport;
