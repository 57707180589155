import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2%;
  margin-left: 2%;
`;

export const Container = styled.div`
  width: 100%;
  margin-left: 1%;
`;

export const CheckBoxOption = styled.div`
  margin-top: 5px;
`;

export const ButtonBuscar = styled.button`
  width: 15%;
  max-width: 150px;
  min-width: 100px;
  min-height: 40px;
  max-height: 45px;
  border: 1px solid #1a8080;
  color: #1a8080;
  background: #fff;
  margin-right: 15px;
  margin-top: 10px;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: #1a8080;
    color: #fff;
  }
`;
