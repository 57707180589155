import React, { useCallback } from "react";

import { IDefaultFieldsContent } from "../../interfaces/IDefaultPages";
import Select from "../Select";
import Input from "../Input";
import InputUpload from "../InputUpload";

import { BoxFile } from "./styles";

interface FiltersProps extends IDefaultFieldsContent {
  existingValues?: { [key: string]: number | string };
  isEdit: boolean;
}

export const Register: React.FC<FiltersProps> = ({
  metadata,
  readonly,
  type,
  options,
  required,
  quickRegister,
  existingValues,
  isEdit,
}) => {
  const handleInitialValue = useCallback(() => {
    if (existingValues && options) {
      const index = options.findIndex(
        (option) => option.value === existingValues[metadata]
      );

      return options[index];
    }

    return undefined;
  }, [existingValues, metadata, options]);

  if (metadata === "id" && !isEdit) {
    return null;
  }

  if (type === "file") {
    return (
      <BoxFile>
        <InputUpload name={metadata} placeholder={metadata} />
      </BoxFile>
    );
  }

  if (type === "select" || type === "boolean") {
    return (
      <Select
        name={metadata}
        placeholder={metadata}
        options={options}
        isClearable
        isDisabled={readonly}
        defaultValue={handleInitialValue}
        quickRegister={quickRegister}
      />
    );
  }

  return (
    <Input
      hidden={metadata === "id"}
      type={type}
      step="any"
      name={metadata}
      readOnly={readonly}
      required={required}
      placeholder={metadata}
      quickRegister={quickRegister}
    />
  );
};
