import React from "react";

import { MdCancel } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import { IconType } from "react-icons";
import { Container } from "./styles";

import { MainButton } from "../MainButton";
import { ModalBackground } from "../ModalBackground";

interface ConfirmWindowProps {
  text: string;
  doAfterConfirm(): void;
  doAfterCancel(): void;
  option1?: string;
  icon1?: IconType;
  option2?: string;
  icon2?: IconType;
  // closeModal(state: boolean): void;
}

export const ConfirmWindow: React.FC<ConfirmWindowProps> = ({
  doAfterCancel,
  doAfterConfirm,
  text,
  option1,
  icon1,
  option2,
  icon2,
}) => {
  return (
    <ModalBackground>
      <Container>
        <div style={{ paddingBottom: "25px" }}>
          <p>{text}</p>
        </div>
        <div style={{ justifySelf: "flex-end" }}>
          {option1 && icon1 ? (
            <>
              <MainButton
                type="button"
                onClick={doAfterCancel}
                buttonColor="#f44336"
              >
                {React.createElement(icon1, { width: 12, height: 12 })}
                {option1}
              </MainButton>
            </>
          ) : (
            <MainButton
              type="button"
              onClick={doAfterCancel}
              buttonColor="#f44336"
            >
              <MdCancel />
              Cancelar
            </MainButton>
          )}
          {option2 && icon2 ? (
            <>
              <MainButton type="button" onClick={doAfterConfirm}>
                {React.createElement(icon2, { width: 12, height: 12 })}
                {option2}
              </MainButton>
            </>
          ) : (
            <MainButton type="button" onClick={doAfterConfirm}>
              <FaRegTrashAlt />
              Excluir
            </MainButton>
          )}
        </div>
      </Container>
    </ModalBackground>
  );
};
