/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback, useState } from "react";

import { AiOutlineFilePdf } from "react-icons/ai";

import { saveAs } from "file-saver";

import { InputUpload } from "../../../../../components";
import api from "../../../../../service/api";
import verifyIfContentIsEmpty from "../../../../../utils/verifyIfContentIsEmpty";

import { InfoProvider } from "../styles";
import { ImagePreviewContainer } from "./styles";

interface ArchivesFieldsProps {
  id: string | undefined;
}

export const ArchivesFields: React.FC<ArchivesFieldsProps> = ({ id }) => {
  const [preview, setPreview] = useState<
    { id: number; arquivo: string; url: string }[]
  >();

  const fetchArchives = useCallback(async () => {
    const { data } = await api.get(
      `/pages/propertiesPictures?table=false&Propriedade=${id}`
    );

    const { content } = data;

    const isEmpty = verifyIfContentIsEmpty(content);

    if (!isEmpty) {
      setPreview(
        data.content.map((element: any) => ({
          id: element.id,
          url: element.Arquivo,
          arquivo: element.Descrição,
        }))
      );
    }
  }, [id]);

  const handleDelete = useCallback(
    async (imageId: number) => {
      await api.delete(`/pages/propertiesPictures/${imageId}`);

      if (preview) {
        const tmp = [...preview];

        const index = tmp.findIndex((x) => x.id === imageId);

        tmp.splice(index, 1);

        setPreview([...tmp]);
      }
    },
    [preview]
  );

  const handleDownload = useCallback(async (fileName: string) => {
    const file = await api.get(`uploads/${fileName}`, {
      responseType: "blob",
    });

    const splited = fileName.split(".");

    const fileExtension = splited[splited.length - 1];

    const fileBlob = new Blob([file.data], {
      type: `application/${fileExtension}`,
    });

    saveAs(fileBlob, `${Date.now()}.${fileExtension}`);
  }, []);

  useEffect(() => {
    if (id) {
      fetchArchives();
    }
  }, [fetchArchives, id]);

  return (
    <InfoProvider>
      <h2>Arquivos</h2>
      <div>
        <div>
          <InputUpload name="prop_anexos" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            {preview &&
              preview.map((element) => (
                <ImagePreviewContainer>
                  {element.arquivo.match("pdf") ? (
                    <AiOutlineFilePdf size={52} />
                  ) : (
                    <img
                      src={element.url}
                      alt="Preview"
                      width="80"
                      height="45"
                      key={element.id}
                    />
                  )}

                  <div>
                    <button
                      style={{ color: "red", border: "1px solid red" }}
                      type="button"
                      onClick={() => handleDelete(element.id)}
                    >
                      APAGAR
                    </button>
                    <button
                      style={{ color: "teal", border: "1px solid teal" }}
                      type="button"
                      onClick={() => handleDownload(element.arquivo)}
                    >
                      BAIXAR
                    </button>
                  </div>
                </ImagePreviewContainer>
              ))}
          </div>
        </div>
      </div>
    </InfoProvider>
  );
};
