import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";

import Tooltip from "@material-ui/core/Tooltip";
import api from "../../../service/api";

import { ActionButton } from "../ActionButton";

import Request from "../dto";

const Edit: React.FC<Request> = ({ id, existingValues }) => {
  const params: { submenu: string } = useParams();

  const handleEdit = useCallback(async () => {
    // nao aparecer o campo senha
    const { data } = await api.get(`pages/${params.submenu}/${id}`);

    existingValues(data, "edit");
  }, [params, id, existingValues]);

  return (
    <Tooltip title="Editar">
      <div>
        <ActionButton onClick={handleEdit}>
          <FaRegEdit />
        </ActionButton>
      </div>
    </Tooltip>
  );
};

export default Edit;
