import styled from "styled-components";

export const MessageError = styled.p`
  font-size: 13px;
  color: red;

  margin-bottom: 5px;
`;

export const Container = styled.div`
  border-radius: 1.5px;
  border-bottom: 0.5px solid #999;
  padding: 8px;
  color: #333;
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: #333;

    &::placeholder {
      color: #666;
    }
  }

  &:focus-within {
    box-shadow: 0 0 3px 0px #126b67;
    border-color: white;
    outline: none;
  }
  svg {
    margin-right: 16px;
  }
`;
