import React, { useCallback, useEffect } from "react";
import Map from "ol/Map";

import * as proj from "ol/proj";
import OSM from "ol/source/OSM";
import Vector from "ol/source/Vector";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { Polygon } from "ol/geom";
import Feature from "ol/Feature";

import { Stroke, Fill, Circle, Style } from "ol/style";
import View from "ol/View";

// import { VIEW_CENTER } from "../../config/ol";

import { MapContainer } from "./styles";
import api from "../../../../../../service/api";

interface OLMapProps {
  id: string;
}

const OLMap: React.FC<OLMapProps> = ({ id }) => {
  const handleMap = useCallback(async () => {
    const center = proj.transform(
      [-49.7082739, -24.2481922],
      "EPSG:4326",
      "EPSG:3857"
    );

    const { data } = await api.get(`/properties/geom/${id}`);

    const { geom } = data;

    if (geom) {
      let clean = geom;

      ["MULTIPOLYGON", "(", ")"].forEach((element) => {
        clean = clean.replaceAll(element, "");
      });

      const array = clean.split(",");

      const draw = array.map((element: string) => {
        const tmp = element.split(" ");

        return proj.transform(
          [parseFloat(tmp[0]), parseFloat(tmp[1])],
          "EPSG:4326",
          "EPSG:3857"
        );
      });

      const polygon = new Polygon([draw]);

      const feature = new Feature({
        geometry: polygon,
      });

      const vectorSource = new Vector({
        features: [feature],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(255, 255, 255, 0.2)",
          }),
          stroke: new Stroke({
            color: "black",
            width: 2,
          }),
          image: new Circle({
            radius: 7,
            fill: new Fill({
              color: "black",
            }),
          }),
        }),
      });

      const layers = [
        new TileLayer({
          source: new OSM(),
        }),
        vectorLayer,
      ];

      const view = new View({
        center,
        zoom: 12,
      });

      view.fit(polygon);

      const map = new Map({
        layers,
        target: "map",
        view,
      });

      const olControls = map.getControls();

      olControls.forEach((element) => map.removeControl(element));
    }
  }, [id]);

  useEffect(() => {
    handleMap();
  }, [handleMap]);

  return <MapContainer id="map" />;
};

export default OLMap;
