import React, { useEffect, useCallback, useState } from "react";
import api from "../../../../../../service/api";
import verifyIfContentIsEmpty from "../../../../../../utils/verifyIfContentIsEmpty";

import { RecalculateButton } from "./components";
import { ModalLoading } from "../../../../../../components";

import { ITerraNuaValues, ITypes } from "./types";

import { TableContainer } from "./styles";

interface TerraNuaProps {
  id: string;
}

export const TerraNua: React.FC<TerraNuaProps> = ({ id }) => {
  const [terraNuaTypes, setTerraNuaTypes] = useState<ITypes[]>();
  const [terraNuaValues, setTerraNuaValues] = useState<ITerraNuaValues[]>();

  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const toggleLoading = useCallback(() => {
    setLoading(!loading);
  }, [loading]);

  const doAfterRecalculate = useCallback(() => {
    setForceUpdate((t) => {
      return t + 1;
    });

    setLoading(false);
  }, []);

  const fetchTerraNuaType = useCallback(async () => {
    const { data } = await api.get(
      "/pages/terraNuaType?table=false&orderByAsc=Ordem"
    );

    setTerraNuaTypes(data.content);
  }, []);

  useEffect(() => {
    fetchTerraNuaType();
  }, [fetchTerraNuaType]);

  const fetchTerraNuaValues = useCallback(async () => {
    const { data } = await api.get(
      `pages/terraNuaProperties?table=false&Propriedade=${id}`
    );

    const { content }: { content: ITerraNuaValues[] } = data;

    const isEmpty = verifyIfContentIsEmpty(data.content);

    if (!isEmpty) {
      setTerraNuaValues(content);
    }
  }, [id]);

  useEffect(() => {
    fetchTerraNuaValues();
  }, [fetchTerraNuaValues, forceUpdate]);

  const renderTerraNuaValue = useCallback(
    (terraNuaTypeId: number) => {
      const terraNuaValue = terraNuaValues?.find(
        (element) => element.Tipo === terraNuaTypeId
      );

      if (terraNuaValue) {
        return (
          <>
            <td>{new Intl.NumberFormat("pt-br").format(terraNuaValue.Área)}</td>
            <td>
              {terraNuaValue.Valor.toLocaleString("pt-br", {
                maximumFractionDigits: 2,
              })}
            </td>
            <td>{terraNuaValue.Percentual} %</td>
          </>
        );
      }

      return null;
    },
    [terraNuaValues]
  );

  return (
    <div>
      <h1>Valores de terra nua</h1>
      <TableContainer>
        {loading && <ModalLoading />}
        <table>
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Área (ha)</th>
              <th>Valor (R$)</th>
              <th>Percentual</th>
            </tr>
          </thead>
          <tbody>
            {terraNuaTypes &&
              terraNuaTypes.map((type) => (
                <tr>
                  <td>
                    <p style={{ color: type.Cor }}>{type.Descrição}</p>
                  </td>
                  {terraNuaValues && renderTerraNuaValue(type.id)}
                </tr>
              ))}
          </tbody>
        </table>
        <RecalculateButton
          terraNuaValues={terraNuaValues}
          doAfterRecalculate={doAfterRecalculate}
          doBeforeRecalculate={toggleLoading}
          propertieId={id}
        />
      </TableContainer>
    </div>
  );
};
