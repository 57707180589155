import React, { createContext, useContext, useState, useCallback } from "react";

interface ForceUpdateContextData {
  update(): void;
  tick: number;
}

const ForceUpdateContext = createContext<ForceUpdateContextData>(
  {} as ForceUpdateContextData
);

const ForceUpdateProvider: React.FC = ({ children }) => {
  const [tick, setTick] = useState(0);

  const update = useCallback(() => {
    setTick((value) => value + 1);
  }, []);

  return (
    <ForceUpdateContext.Provider value={{ update, tick }}>
      {children}
    </ForceUpdateContext.Provider>
  );
};

function useForceUpdate(): ForceUpdateContextData {
  const context = useContext(ForceUpdateContext);

  if (!context) {
    throw new Error(
      "useForceUpdate must be used within an ForceUpdateProvider"
    );
  }

  return context;
}

export { ForceUpdateProvider, useForceUpdate };
