import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";

import { Container } from "./styles";

interface MenuButtonsProps {
  id: string;
  icon: IconName;
  module: string;
}

const MenuButtons: React.FC<MenuButtonsProps> = ({ id, icon, module }) => {
  return (
    <Link
      to={{
        pathname: `/modulos/${btoa(id)}`,
        state: { fromMenu: true },
      }}
      key={id}
    >
      <Container>
        <FontAwesomeIcon icon={["fas", icon]} color="teal" />
        <p>{module}</p>
      </Container>
    </Link>
  );
};

export default MenuButtons;
