import styled from "styled-components";

export const FooterContainer = styled.footer`
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const SelectContainter = styled.div`
  margin-top: 2px;
  width: 13%;
`;
