import styled from "styled-components";

export const LoadingContainer = styled.div`
  height: 20px;
  width: 20px;
`;

export const Container = styled.div`
  display: grid;

  grid-template-columns: auto;

  height: 100vh;
  width: 100vw;

  & > div,
  & form {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & > div {
    align-self: center;

    border-right: 1px solid teal;
  }

  & form {
    div {
      width: 25%;
      margin-bottom: 1%;
    }
  }
`;
export const Button = styled.button`
  border: 0.5px solid #126b67;
  border-radius: 2.5px;

  height: 5%;
  width: 10%;

  background-color: rgba(0, 0, 0, 0);

  padding-left: 1%;
  padding-right: 1%;

  font-weight: 900;
  font-size: 80%;

  color: #126b67;

  &:hover {
    border: 0.5px solid rgba(0, 0, 0, 0);
    background-color: #126b67;

    color: #ffffff;
  }
`;
