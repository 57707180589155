import React from "react";

import { Container } from "./styles";

import {
  MainButton,
  ModalBackground,
} from "../../../../../../../../components";

interface ConfirmWindowProps {
  text: string;
  doAfterConfirm(): void;
  doAfterCancel(): void;
}

export const ConfirmWindow: React.FC<ConfirmWindowProps> = ({
  doAfterCancel,
  doAfterConfirm,
  text,
}) => {
  return (
    <ModalBackground>
      <Container>
        <div style={{ paddingBottom: "25px" }}>
          <p>{text}</p>
        </div>
        <div style={{ justifySelf: "flex-end" }}>
          <MainButton type="button" onClick={doAfterConfirm}>
            Sim
          </MainButton>
          <MainButton
            type="button"
            onClick={doAfterCancel}
            buttonColor="#f44336"
          >
            Não
          </MainButton>
        </div>
      </Container>
    </ModalBackground>
  );
};
