import styled from "styled-components";

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;

  label {
    width: 100%;
    display: flex;

    margin-bottom: 15px;
    div {
      margin-right: 10px;
      margin-left: 10px;
      svg {
        margin-right: 15px;
      }
    }
  }
`;
