import React from "react";

import Highcharts from "highcharts";
import SunBurst from "highcharts/modules/sunburst";
import HighchartsReact from "highcharts-react-official";

interface ChartsProps extends HighchartsReact.Props {
  isSunBurst?: boolean;
  options: Highcharts.Options;
}

const Charts: React.FC<ChartsProps> = ({
  options,
  isSunBurst = false,
  ...rest
}) => {
  if (isSunBurst) {
    return (
      <HighchartsReact
        highcharts={SunBurst(Highcharts)}
        options={options}
        {...rest}
      />
    );
  }
  return (
    <HighchartsReact highcharts={Highcharts} options={options} {...rest} />
  );
};

export default Charts;
