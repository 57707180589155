import axios from "axios";

// export const url = "http://localhost:8085";
// export const url = "http://itrjgv.semv.com.br/";
export const url = "https://itr-api.semv.com.br/";

const api = axios.create({
  baseURL: url,
});

export default api;
