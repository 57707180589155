import React, { useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FaRegSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { Filters } from "./filters";
import { Register } from "./register";
import { formatFields } from "./functions";

import { MainButton } from "../MainButton";

import { useFilters } from "../../hooks/filters";

import { IDefaultFieldsContent } from "../../interfaces/IDefaultPages";

import { FiltersContainer, ContainerInputs, BoxButton } from "./styles";
import api from "../../service/api";

interface Form {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface FilterFields {
  fields: IDefaultFieldsContent[];
  type: "filters" | "register" | "clone" | "edit";
  existingValues?: { [key: string]: number | string };
  doAfterRegister?(): void;
  doAfterCancel?(): void;
  numberOfColumns?: number;
}

const ButtonType = (type: string) => {
  switch (type) {
    case "filters":
      return "BUSCAR";
    case "edit":
      return "EDITAR";
    default:
      return "CADASTRAR";
  }
};

const Fields: React.FC<FilterFields> = ({
  fields,
  type,
  existingValues,
  doAfterRegister,
  doAfterCancel,
  numberOfColumns = 4,
}) => {
  const params: { id: string; submenu: string } = useParams();

  const formRef = useRef<FormHandles>(null);
  const { setFilters, saveFilters } = useFilters();

  const handleRegister = useCallback(
    async (data: Form) => {
      // if (upload) {
      //   for (const campo of upload) {
      //     const form = new FormData();

      //     form.append("file", formulario[campo]);
      //     const { data } = await api.post("/uploads", form);
      //     // eslint-disable-next-line no-param-reassign
      //     formulario[campo] = ` tmp/${data.filename}`;
      //   }
      // }

      const formatedData = formatFields(fields, data);

      await api.post(`/pages/${params.submenu}`, formatedData);

      if (doAfterRegister) {
        doAfterRegister();
      }
    },
    [params, doAfterRegister, fields]
  );

  const handleEdit = useCallback(
    async (data: Form) => {
      const formatedData = formatFields(fields, data);

      await api.put(`/pages/${params.submenu}`, formatedData);

      if (doAfterRegister) {
        doAfterRegister();
      }
    },
    [params, doAfterRegister, fields]
  );

  const renderInputs = useCallback(
    (filter: IDefaultFieldsContent, index: number) => {
      if (type === "filters") {
        return <Filters {...filter} key={index} />;
      }

      return (
        <Register
          {...filter}
          existingValues={existingValues}
          key={index}
          isEdit={type === "edit"}
        />
      );
    },
    [existingValues, type]
  );

  const handleSearch = useCallback(
    (data: Form) => {
      const keys = Object.keys(data);

      const validFilters: string[] = [];

      keys.forEach((key) => {
        if (data[key] !== "") {
          validFilters.push(`${key}=${data[key]}`);
        }
      });

      const stringfyValidFilters = validFilters.join("&");

      setFilters(stringfyValidFilters);
      saveFilters(stringfyValidFilters);
    },
    [saveFilters, setFilters]
  );

  const handleSubmit = useCallback(
    (data: Form) => {
      switch (type) {
        case "filters":
          handleSearch(data);
          break;

        case "edit":
          handleEdit(data);
          break;

        default:
          handleRegister(data);
          break;
      }
    },
    [type, handleSearch, handleRegister, handleEdit]
  );

  useEffect(() => {
    if (existingValues) formRef.current?.setData(existingValues);
  }, [existingValues]);

  return (
    <FiltersContainer>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <ContainerInputs numberOfColumns={numberOfColumns}>
          {fields.map((filter, index) => renderInputs(filter, index))}
        </ContainerInputs>

        <BoxButton>
          {type !== "filters" && (
            <MainButton
              type="button"
              onClick={doAfterCancel}
              buttonColor="#f44336"
            >
              <MdCancel style={{ marginRight: "10px" }} />
              CANCELAR
            </MainButton>
          )}
          <MainButton type="submit">
            <FaRegSave style={{ marginRight: "10px" }} />
            {ButtonType(type)}
          </MainButton>
        </BoxButton>
      </Form>
    </FiltersContainer>
  );
};

export default Fields;
