import React, { useCallback, useState } from "react";

import ReactLoading from "react-loading";
import Tooltip from "@material-ui/core/Tooltip";
import { useParams } from "react-router-dom";

import { MdMail } from "react-icons/md";
import api from "../../../service/api";

import { ActionButton } from "../ActionButton";
import Request from "../dto";
import { LoadingContainer } from "./styles";
import { showToast } from "../..";

// const SendPassword: React.FC = () => {
//   const [count, setCount] = useState<boolean>(false);

//   const updateValue = useCallback(() => {
//     setCount(!count);
//   }, [count]);

//   return (
//     <>
//       <p>{count.toString()}</p>
//       <button type="button" onClick={updateValue}>
//         adicionar
//       </button>
//     </>
//   );
// };

const SendPassword: React.FC<Omit<Request, "existingValues">> = ({ id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const params: { id: string; submenu: string } = useParams();

  const handleSendPassword = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`pages/${params.submenu}/${id}`);
      await api.post(`pages/forgotPassword`, data);

      showToast({
        type: "success",
        message: `Email enviado para  ${data.Email}`,
      });
    } finally {
      setLoading(false);
    }
  }, [id, params.submenu]);

  if (loading) {
    return (
      <LoadingContainer>
        <ReactLoading height="20px" width="20px" color="teal" />
      </LoadingContainer>
    );
  }

  return (
    <Tooltip title="Enviar senha">
      <div>
        <ActionButton onClick={handleSendPassword}>
          <MdMail />
        </ActionButton>
      </div>
    </Tooltip>
  );
};

export default SendPassword;
