import React from "react";

import { FiltersProvider, useFilters } from "./filters";
import { FieldsProvider, useFields } from "./fields";
import { ForceUpdateProvider, useForceUpdate } from "./forceUpdate";
import { OrderProvider, useOrder } from "./order";
import { PaginationProvider, usePagination } from "./pagination";
import { LayersProvider, useLayers } from "./layers";
import { OpacityProvider, useOpacity } from "./opacity";

import { useAuth } from "./auth";

export const AppProviders: React.FC = ({ children }) => (
  <FieldsProvider>
    <FiltersProvider>
      <OrderProvider>
        <ForceUpdateProvider>
          <LayersProvider>
            <OpacityProvider>
              <PaginationProvider>{children}</PaginationProvider>
            </OpacityProvider>
          </LayersProvider>
        </ForceUpdateProvider>
      </OrderProvider>
    </FiltersProvider>
  </FieldsProvider>
);

export {
  useFields,
  useFilters,
  useForceUpdate,
  useOrder,
  usePagination,
  useAuth,
  useLayers,
  useOpacity,
};
