/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const styleInput = {
  control: (state: any) => ({
    boxShadow: state.isFocused ? "0 0 3px 0px #126b67" : "none",
    borderTop: state.isFocused ? "none" : "1px solid #fff",
    borderLeft: state.isFocused ? "none" : "1px solid #fff",
    borderRight: state.isFocused ? "none" : "1px solid #fff",
    borderBottom: state.isFocused ? "none" : "1px solid #999",
    display: "flex",
    height: "52px",
  }),
  menu: () => ({
    width: "100%",
  }),
  menuList: () => ({
    backgroundColor: "#fafafa",
    boxShadow: "0px 3px 7px 0px #ccc",
    borderTop: "1px solid #f5f5f5",
    marginTop: "-2px",
  }),
  placeholder: () => ({
    color: "#666",
  }),
  container: () => ({
    width: "100%",
  }),
};
