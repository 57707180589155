import styled from "styled-components";

export const Dot = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
`;

export const TableContainer = styled.section`
  font-size: 16px;
  padding: 15px;
  margin: 0px 20px;
  overflow: auto;

  table {
    display: table;

    margin-top: 15px;
    width: 100%;
    border-radius: 5.5px;
    border: 1px solid #bbb;
    box-shadow: 0px 0px 15px -5px #666;

    thead {
      th {
        text-align: start;
        padding: 5px;
        height: 50px;
        border-bottom: 1px solid #bbb;

        /* &:first-child {
          width: 80px;
        } */
      }
    }

    tbody {
      tr {
        text-align: start;

        &:nth-child(even) {
          background-color: #f6f6f6;
        }

        &:hover {
          background-color: #bbb;
        }
      }

      td {
        font-size: 0.9em;
        height: 25px;
        padding: 7px;
      }
    }
  }
`;
