import React, { useState } from "react";

import { ILayers } from "../../controller";

import { useLayers } from "../../../../hooks";
import { useOpacity } from "../../../../hooks/opacity";
import { Container } from "./styles";

interface LayerItemProps {
  layer: ILayers;
}

const LayerItem: React.FC<LayerItemProps> = ({ layer }) => {
  const { setOpacity } = useOpacity();
  const { removeLayer, saveSelectedLayers, selectedLayers } = useLayers();
  const [checked, setChecked] = useState<boolean>(
    selectedLayers.includes(layer["Nome Geoserve"])
  );
  const [rangeValue, setRangeValue] = useState<number>(100);

  const handleValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setChecked(isChecked);

    if (isChecked) {
      saveSelectedLayers([event.target.id]);

      return;
    }

    removeLayer(event.target.id);
  };

  const handleRangeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const float = parseFloat(value);

    setRangeValue(float);

    setOpacity({ layer: layer["Nome Geoserve"], opacity: float / 100.0 });
  };

  return (
    <label htmlFor={layer.id.toString()} key={layer.id}>
      <div>
        <p>{layer.Nome}</p>
        <input
          type="checkbox"
          checked={checked}
          key={layer.id}
          id={layer["Nome Geoserve"]}
          onChange={handleValueChanged}
        />
      </div>
      {checked && (
        <input
          type="range"
          min="0"
          max="100"
          value={rangeValue}
          onChange={handleRangeChanged}
        />
      )}
    </label>
  );
};

interface LayersMenuProps {
  layers: ILayers[];
}

export const LayersMenu: React.FC<LayersMenuProps> = ({ layers }) => {
  return (
    <Container>
      {layers.map((layer) => (
        <LayerItem layer={layer} />
      ))}
    </Container>
  );
};
