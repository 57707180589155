import React, { useCallback } from "react";

import { AiOutlineFilePdf } from "react-icons/ai";

import { saveAs } from "file-saver";

import { PropertieInfo } from "../../styles";
import api from "../../../../../../service/api";

interface FilesPreviewProps {
  files: { fileName: string; url: string }[];
}

export const FilesPreview: React.FC<FilesPreviewProps> = ({ files }) => {
  const handleDownload = useCallback(async (fileName: string) => {
    const file = await api.get(`uploads/${fileName}`, {
      responseType: "blob",
    });

    const splited = fileName.split(".");

    const fileExtension = splited[splited.length - 1];

    const fileBlob = new Blob([file.data], {
      type: `application/${fileExtension}`,
    });

    saveAs(fileBlob, `${Date.now()}.${fileExtension}`);
  }, []);

  return (
    <div>
      <h1>Arquivos</h1>
      <PropertieInfo>
        {files.length > 0 ? (
          files.map((element) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "130px",
                width: "100%",
                maxWidth: "200px",
                marginLeft: "10px",
                justifyContent: "space-between",
                overflowWrap: "break-word",
              }}
            >
              <p>{element.fileName}</p>
              <button
                type="button"
                onClick={() => handleDownload(element.fileName)}
              >
                {element.fileName.match("pdf") ? (
                  <AiOutlineFilePdf size={52} />
                ) : (
                  <img src={element.url} alt="Preview" width="80" height="45" />
                )}
              </button>
            </div>
          ))
        ) : (
          <h4>Nenhum arquivo adicionado até o momento</h4>
        )}
      </PropertieInfo>
    </div>
  );
};
