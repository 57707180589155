import React, { useCallback } from "react";
import { FaRegClone } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";

import api from "../../../service/api";

import { ActionButton } from "../ActionButton";
import Request from "../dto";

const Clone: React.FC<Request> = ({ id, existingValues }) => {
  const params: { submenu: string } = useParams();

  const handleClone = useCallback(async () => {
    const { data } = await api.get(`pages/${params.submenu}/${id}`);

    existingValues(data);
  }, [params, id, existingValues]);

  return (
    <Tooltip title="Clonar">
      <div>
        <ActionButton onClick={handleClone}>
          <FaRegClone />
        </ActionButton>
      </div>
    </Tooltip>
  );
};

export default Clone;
