import React from "react";
import { Switch } from "react-router-dom";
import { DefaultFrame } from "../pages";
import PageNotFound from "../errors/404PageNotFound";
import SignIn from "../pages/Auth/Signin";
import Consulta from "../pages/App/DefaultPages/Consulta";
import Route from "./Routes";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/dashboard" exact component={DefaultFrame} strict isPrivate />
    <Route path="/modulos/:id" exact component={DefaultFrame} isPrivate />
    <Route
      path="/modulos/:id/:submenu"
      exact
      component={DefaultFrame}
      isPrivate
    />
    <Route
      path="/modulos/:id/:submenu/:itemId"
      exact
      component={DefaultFrame}
      isPrivate
    />
    <Route
      path="/consulta/tabs/:submenu"
      exact
      component={Consulta}
      isPrivate
    />
    <Route path="/propertie/:id" exact component={DefaultFrame} isPrivate />
    <Route path="/propertie" exact component={DefaultFrame} isPrivate />

    {/* path = nome da rota precisa ser a mesma declarada no outro arquivo */}
    <Route path="/senha" exact component={DefaultFrame} isPrivate />

    {/* <Route path="/permissions" exact component={DefaultFrame} isPrivate /> */}
    <Route path="/404" component={PageNotFound} isPrivate />
  </Switch>
);

export default Routes;
