import React, { useEffect, useCallback, useState } from "react";

import { PropertieInfo } from "../../styles";

import api from "../../../../../../service/api";

interface OwnersProps {
  propertieId: string;
}

interface IOwners {
  pes_nome: string;
  pes_documento: string;
}

export const Owners: React.FC<OwnersProps> = ({ propertieId }) => {
  const [currentOwners, setCurrentOwners] = useState<IOwners[]>();

  const fetchOwners = useCallback(async () => {
    const { data } = await api.get(`properties/owners/${propertieId}`);

    setCurrentOwners(data);
  }, [propertieId]);

  useEffect(() => {
    fetchOwners();
  }, [fetchOwners]);
  return (
    <div>
      <h1>Proprietários</h1>
      <PropertieInfo>
        {currentOwners && currentOwners.length > 0 ? (
          currentOwners.map((owner) => (
            <div>
              <h4>{owner.pes_nome}</h4>
              <p>{owner.pes_documento || "------"}</p>
            </div>
          ))
        ) : (
          <h4>Nenhum proprietário adicionado até o momento</h4>
        )}
      </PropertieInfo>
    </div>
  );
};
