import React, { useEffect, useState, useCallback } from "react";

import { TableContainer } from "./styles";
import { IPropertiesByArea } from "../../interfaces";

interface TableProps {
  data: IPropertiesByArea;
}

interface ITotals {
  total: number;
  notDelimited: number;
  delimited: number;
}

const Table: React.FC<TableProps> = ({ data }) => {
  const [totals, setTotals] = useState<ITotals>({} as ITotals);
  const [areas, setAreas] = useState<string[]>([]);

  const getSums = useCallback(() => {
    let total = 0;
    let notDeliTot = 0;
    let deliTot = 0;
    areas.forEach((element) => {
      total += data[element].total;
      notDeliTot += data[element].notDelimited;
      deliTot += data[element].delimited;
    });

    setTotals({
      total,
      delimited: deliTot,
      notDelimited: notDeliTot,
    });
  }, [areas, data]);

  useEffect(() => {
    getSums();
  }, [getSums]);

  useEffect(() => {
    setAreas(Object.keys(data));
  }, [data]);

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            <th>Área (ha)</th>
            <th>Demarcadas</th>
            <th>Não demarcadas</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {areas.map((element) => (
            <tr key={element}>
              <td>{element}</td>
              <td>{data[element].delimited}</td>
              <td>{data[element].notDelimited}</td>
              <td>{data[element].total}</td>
            </tr>
          ))}
          <tr>
            <td />
            <td>{totals.delimited}</td>
            <td>{totals.notDelimited}</td>
            <td>{totals.total}</td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  );
};

export default Table;
