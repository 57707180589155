import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 76vh;
`;

export const Button = styled.button`
  min-width: 150px;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 2.5px 0px #bbb;
  border: 1px solid #bbb;
  border-radius: 3.5px;
  background-color: #fcfcfc;
  height: 112px;
  font-size: 0.9em;
  margin-right: 25px;
  padding: 5px 0px 5px 0px;
  margin-bottom: 5px;

  &:hover {
    background-color: #f2f2f2;
    color: #bbb !important;
  }
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  justify-content: center;
  align-items: flex-start;
  width: 230px;
  text-align: left;
  margin: 0;
  margin-left: -22px;
  overflow: auto;

  & > button {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    font-size: 0.8rem;
    font-family: "Montserrat", sans-serif;
    color: #333;
    cursor: pointer;
    margin-bottom: 7.5px;
  }
`;
