import React, { useCallback, useState } from "react";
import ReactLoading from "react-loading";
import { saveAs } from "file-saver";
import { Container, Button } from "./styles";

import { MapIframe } from "../../../../../../components";

import api, { url } from "../../../../../../service/api";

interface ActionsProps {
  propertieId: string;
}

interface IIframe {
  url: string;
  password: string;
}

export const Actions: React.FC<ActionsProps> = ({ propertieId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [iframe, setIframe] = useState<IIframe>();

  const handleCloseIFrame = useCallback(() => {
    setIframe(undefined);
  }, []);

  const handleResumo = useCallback(async () => {
    const { data } = await api.post("documents/queue");

    const { password } = data;

    setIframe({
      url: `${url}/documents/itr/resume/jaguariaiva/${propertieId}/${password}`,
      password,
    });
  }, [propertieId]);

  const handleLaudo = useCallback(async () => {
    try {
      setLoading(true);
      const laudoPayload = {
        propertieId,
      };

      const { data } = await api.post("/documents/itr/laudo", laudoPayload);

      setTimeout(async () => {
        const laudo = await api.get(`/documents/itr/laudo/${data}`, {
          responseType: "blob",
        });
        const laudoBloc = new Blob([laudo.data], { type: "application/pdf" });

        saveAs(laudoBloc, `laudo-${Date.now()}.pdf`);

        await api.delete(`/documents/itr/laudo/${data}`);
      }, 1000);
    } finally {
      setLoading(false);
    }
  }, [propertieId]);

  if (iframe) {
    return (
      <MapIframe
        password={iframe.password}
        url={iframe.url}
        handleCloseIframe={handleCloseIFrame}
      />
    );
  }

  return (
    <Container>
      <Button type="button" onClick={handleResumo}>
        RESUMO
      </Button>
      <Button type="button" onClick={handleLaudo}>
        LAUDO
        {loading && <ReactLoading height="20px" width="20px" color="teal" />}
      </Button>
    </Container>
  );
};
