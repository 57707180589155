/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { FormHandles, Scope } from "@unform/core";
import { AiOutlinePlus } from "react-icons/ai";
import { Menu } from "./styles";
import { IFunctional, IResponse, ISubMenu } from "../interfaces";
import CheckboxInput from "../../../../components/Checkbox";
import { ButtonPlus } from "../../../../components/QuickRegister/styles";

interface MenuCheckBoxProps {
  response: IResponse;
  buttons?: IFunctional;
  formRef: React.RefObject<FormHandles>;
}

const MenuCheckBox: React.FC<MenuCheckBoxProps> = ({ response, formRef }) => {
  // const [visibleOptions, setVisibleOptions] = useState(false);
  const [actionId, setActionId] = useState("");
  // const [showMore, setShowMore] = useState(true);

  const selectChildren = async (
    checked: boolean,
    id: number,
    menu?: string
  ) => {
    if (menu) {
      if (checked) {
        formRef.current?.setFieldValue(`moduleValues.menuValues.${menu}`, [
          menu.toString(),
        ]);
        formRef.current?.setFieldValue(
          `moduleValues.menuValues.submenuValues.${id}`,
          [id.toString()]
        );
      } else {
        formRef.current?.clearField(`moduleValues.menuValues.${menu}`);
        formRef.current?.clearField(
          `moduleValues.menuValues.submenuValues.${id}`
        );
      }
    }
  };
  const showOptions = (id: string, myId: string) => {
    if (id === myId) return true;

    return false;
  };
  const renderSubMenuOptions = (
    module: string,
    id: string,
    submenu?: string[],
    functionalButtons?: string[]
  ) => {
    // const checkAll = (id: any, checked: boolean) => {
    //   submenu?.map((index) => {
    //     console.log(index);

    //     if (checked && submenu) {
    //       formRef.current?.setFieldValue(
    //         `moduleValues.menuValues.submenuValues.options.${index}`,
    //         [index.toString()]
    //       );
    //     }
    //     console.log(
    //       "getField",
    //       formRef.current?.getFieldValue(
    //         `moduleValues.menuValues.submenuValues.options.${index}`
    //       )
    //     );
    //   });
    //   // else {
    //   //   formRef.current?.clearField(
    //   //     `moduleValues.menuValues.submenuValues.${element.id}`
    //   //   );
    //   // }
    // };

    return (
      <Scope path="options">
        <div
          style={{
            display: showOptions(id, actionId) ? "grid" : "none",
            marginLeft: 45,
          }}
        >
          {submenu &&
            submenu?.map((index: string) => (
              <CheckboxInput
                name={id + index}
                options={[
                  {
                    id: id + index,
                    value: `${id}/1/${index}`,
                    label: index,
                  },
                ]}
              />
            ))}
          {functionalButtons &&
            functionalButtons?.map((index: string) => (
              <CheckboxInput
                name={id + index}
                options={[
                  {
                    id: id + index,
                    value: `${id}/2/${index}`,
                    label: index,
                  },
                ]}
              />
            ))}
        </div>
      </Scope>
    );
  };

  // const collapseButton = () => {
  //   setActionId("null");
  // };
  const expandButton = (id: number) => {
    setActionId(id.toString());
  };

  const renderSubMenu = (module: string, menu: string) => {
    const checkAll = (id: string, checked: boolean) => {
      response[module][menu].map((element) => {
        element.actions.map((index: string) => {
          if (checked)
            formRef.current?.setFieldValue(
              `moduleValues.menuValues.submenuValues.options.${id + index}`,
              [id + index.toString()]
            );
          if (!checked)
            formRef.current?.clearField(
              `moduleValues.menuValues.submenuValues.options.${id + index}`
            );
        });
        element.functional_buttons.map((index: string) => {
          if (checked)
            formRef.current?.setFieldValue(
              `moduleValues.menuValues.submenuValues.options.${id + index}`,
              [id + index.toString()]
            );
          if (!checked)
            formRef.current?.clearField(
              `moduleValues.menuValues.submenuValues.options.${id + index}`
            );
        });
      });
    };

    return (
      <Scope path="submenuValues">
        {response[module][menu].map((submenu) => (
          <>
            <div style={{ display: "flex", marginLeft: 25, marginRight: 25 }}>
              <CheckboxInput
                name={submenu.id.toString()}
                options={[
                  {
                    id: submenu.id.toString(),
                    value: submenu.id.toString(),
                    label: submenu.subMenu,
                    actions: submenu.actions,
                    functionalButtons: submenu.functional_buttons,
                  },
                ]}
                doAfterChangeValue={checkAll}
              />
              <ButtonPlus
                type="button"
                onClick={() => expandButton(submenu.id)}
              >
                <AiOutlinePlus />
              </ButtonPlus>
              {/* <ButtonPlus type="button" onClick={() => collapseButton()}>
                <AiOutlineMinus />
              </ButtonPlus> */}
            </div>
            {renderSubMenuOptions(
              module,
              submenu.id.toString(),
              submenu.actions,
              submenu.functional_buttons
            )}
          </>
        ))}
      </Scope>
    );
  };

  const MenuCheckbox = (menu: string, module: string) => {
    const checkAll = (id: string, checked: boolean) => {
      response[module][menu].forEach((element) => {
        if (checked) {
          formRef.current?.setFieldValue(
            `moduleValues.menuValues.submenuValues.${element.id}`,
            [element.id.toString()]
          );
        } else
          formRef.current?.clearField(
            `moduleValues.menuValues.submenuValues.${element.id}`
          );

        element.actions.map((index: string) => {
          if (checked) {
            formRef.current?.setFieldValue(
              `moduleValues.menuValues.submenuValues.options.${
                element.id + index
              }`,
              [element.id + index.toString()]
            );
          } else
            formRef.current?.clearField(
              `moduleValues.menuValues.submenuValues.options.${
                element.id + index
              }`
            );
        });
        element.functional_buttons.map((index: string) => {
          if (checked)
            formRef.current?.setFieldValue(
              `moduleValues.menuValues.submenuValues.options.${
                element.id + index
              }`,
              [element.id + index.toString()]
            );
          if (!checked)
            formRef.current?.clearField(
              `moduleValues.menuValues.submenuValues.options.${
                element.id + index
              }`
            );
        });
        // const temp = formRef.current?.getFieldValue(
        //   `moduleValues.menuValues.submenuValues.${element.id}`
        // );
        // if (temp.length === 0) {
        //   setVisibleOptions(false);
        // } else setVisibleOptions(true);
      });
    };

    return (
      <>
        <CheckboxInput
          name={menu}
          options={[
            {
              id: menu,
              value: menu,
              label: menu,
            },
          ]}
          doAfterChangeValue={checkAll}
        />
        {renderSubMenu(module, menu)}
      </>
    );
  };

  const renderMenu = (module: string) => {
    const submenu = Object.keys(response[module]);
    return (
      <div style={{ marginLeft: 20 }}>
        <Scope path="menuValues">
          {submenu.map((menu) => MenuCheckbox(menu, module))}
        </Scope>
      </div>
    );
  };

  const ModuleCheckbox = (module: string) => {
    const checkAll = (id: string, checked: boolean) => {
      Object.keys(response[module]).forEach((menu: string) => {
        Object.keys(response[module][menu]).forEach((sub: any) => {
          const element = response[module][menu][sub];
          selectChildren(checked, element.id, menu);
          Object.values(response[module][menu][sub]).forEach((options: any) => {
            const actionButtons: ISubMenu = response[module][menu][sub];

            actionButtons.actions.forEach((index: string) => {
              if (checked)
                formRef.current?.setFieldValue(
                  `moduleValues.menuValues.submenuValues.options.${
                    actionButtons.id + index
                  }`,
                  [actionButtons.id + index.toString()]
                );
              if (!checked)
                formRef.current?.clearField(
                  `moduleValues.menuValues.submenuValues.options.${
                    actionButtons.id + index
                  }`
                );
            });

            actionButtons.functional_buttons.forEach((index: string) => {
              if (checked)
                formRef.current?.setFieldValue(
                  `moduleValues.menuValues.submenuValues.options.${
                    actionButtons.id + index
                  }`,
                  [actionButtons.id + index.toString()]
                );
              if (!checked)
                formRef.current?.clearField(
                  `moduleValues.menuValues.submenuValues.options.${
                    actionButtons.id + index
                  }`
                );
            });
          });
        });
      });
    };
    return (
      <div
        style={{
          marginRight: 10,
          borderRight: "solid",
          borderColor: "teal",
        }}
      >
        <CheckboxInput
          name={module}
          options={[
            {
              id: module,
              value: module.toString(),
              label: module,
            },
          ]}
          doAfterChangeValue={checkAll}
        />
        {renderMenu(module)}
      </div>
    );
  };
  const renderModules = () => {
    const modules = Object.keys(response);
    return (
      <Scope path="moduleValues">
        {modules.map((module) => ModuleCheckbox(module))}
      </Scope>
    );
  };

  return <Menu>{renderModules()}</Menu>;
};

export default MenuCheckBox;
