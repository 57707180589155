import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface OrderContextData {
  orderBy: string | undefined;
  setOrderBy(currentOrder: string): void;
  saveOrderBy(orderBy: string): void;
}

const OrderContext = createContext<OrderContextData>({} as OrderContextData);

const OrderProvider: React.FC = ({ children }) => {
  const [orderBy, setOrderBy] = useState<string>(() => {
    const hasOrder = localStorage.getItem(
      `orderedBy-${window.location.pathname}`
    );
    if (hasOrder) return hasOrder;

    return "orderByAsc=id";
  });

  const saveOrderBy = useCallback((hasOrder: string) => {
    const tmp = hasOrder;

    localStorage.setItem(`orderedBy-${window.location.pathname}`, tmp);

    setOrderBy(tmp);
  }, []);

  useEffect(() => {
    const hasOrder = localStorage.getItem(
      `orderedBy-${window.location.pathname}`
    );
    if (hasOrder) setOrderBy(hasOrder);
    else setOrderBy("orderByAsc=id");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <OrderContext.Provider value={{ orderBy, setOrderBy, saveOrderBy }}>
      {children}
    </OrderContext.Provider>
  );
};

function useOrder(): OrderContextData {
  const context = useContext(OrderContext);

  if (!context) {
    throw new Error("useOrder must be used within an OrderProvider");
  }

  return context;
}

export { OrderProvider, useOrder };
