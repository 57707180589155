import React from "react";

import { IDefaultFieldsContent } from "../../interfaces/IDefaultPages";
import Select from "../Select";
import Input from "../Input";

type FiltersProps = IDefaultFieldsContent;

export const Filters: React.FC<FiltersProps> = ({
  metadata,
  readonly,
  type,
  options,
  required,
}) => {
  if (type === "file") return null;

  if (type === "select" || type === "boolean") {
    return (
      <Select
        name={metadata}
        placeholder={metadata}
        options={options}
        isClearable
        isDisabled={readonly}
      />
    );
  }

  return (
    <Input
      type={type}
      name={metadata}
      readOnly={readonly}
      required={required}
      placeholder={metadata}
    />
  );
};
