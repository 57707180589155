/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useCallback, useState } from "react";

import { useParams } from "react-router-dom";

import verifyIfContentIsEmpty from "../../../../utils/verifyIfContentIsEmpty";

import { IPropertiesResponse } from "../../../../interfaces/IProperties";

import api from "../../../../service/api";

// import { OLMap } from "../../../../components";

import {
  Actions,
  FilesPreview,
  TerraNua,
  Charact,
  Owners,
  OLMap,
} from "./components";
import { Container, InfoProvider, PropertieInfo, MapContainer } from "./styles";

export const PropertiesView: React.FC = () => {
  const params: { itemId: string; submenu: string } = useParams();

  const [preview, setPreview] = useState<{ url: string; fileName: string }[]>(
    []
  );
  const [propertieData, setPropertieData] = useState<IPropertiesResponse>(
    {} as IPropertiesResponse
  );

  const fetchPropertie = useCallback(async () => {
    const { data } = await api.get(`pages/${params.submenu}/${params.itemId}`);
    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (data[key] === "" || !data[key]) {
        data[key] = "------";
      }
    });

    setPropertieData(data);
  }, [params]);

  useEffect(() => {
    fetchPropertie();
  }, [fetchPropertie]);

  const fetchArchives = useCallback(async () => {
    const { data } = await api.get(
      `/pages/propertiesPictures?table=false&Propriedade=${params.itemId}`
    );

    const isEmpty = verifyIfContentIsEmpty(data.content);

    if (!isEmpty) {
      setPreview(
        data.content.map((element: any) => ({
          url: element.Arquivo,
          fileName: element.Descrição,
        }))
      );
    }
  }, [params]);

  useEffect(() => {
    fetchArchives();
  }, [fetchArchives]);

  return (
    <Container>
      <Actions propertieId={params.itemId} />
      <InfoProvider>
        <div>
          <h1>{propertieData["Nome Propriedade"]}</h1>

          <PropertieInfo>
            <div>
              <h3>Ano</h3>
              <p>{propertieData.Ano}</p>
            </div>
            <div>
              <h3>Número do NIRF</h3>
              <p>{propertieData.NIRF}</p>
            </div>
            <div>
              <h3>Tipo do imóvel</h3>
              <p>{propertieData["Tipo Imóvel"]}</p>
            </div>
            <div>
              <h3>Área declarada (ha)</h3>
              <p>{propertieData["Área declarada"]}</p>
            </div>
            <div>
              <h3>Código do INCRA</h3>
              <p>{propertieData["Código Incra"]}</p>
            </div>
            <div>
              <h3>Inscrição municipal</h3>
              <p>{propertieData["Inscrição muncipal"]}</p>
            </div>
          </PropertieInfo>
        </div>

        <Owners propertieId={params.itemId} />

        <div>
          <h1>Endereço</h1>
          <PropertieInfo>
            <div>
              <h3>Município</h3>
              <p>{propertieData["Município"]}</p>
            </div>
            <div>
              <h3>Logradouro</h3>
              <p>{propertieData.Logradouro}</p>
            </div>
            <div>
              <h3>N. Logradouro</h3>
              <p>{propertieData["N Logradouro"]}</p>
            </div>

            <div>
              <h3>CEP</h3>
              <p>{propertieData.CEP}</p>
            </div>

            <div>
              <h3>Complemento</h3>
              <p>{propertieData.Complemento}</p>
            </div>

            <div>
              <h3>Caixa Postal</h3>
              <p>{propertieData["Caixa postal"]}</p>
            </div>

            <div>
              <h3>Localização</h3>
              <p>{propertieData["Localização"]}</p>
            </div>
          </PropertieInfo>
        </div>

        <TerraNua id={params.itemId} />

        <div>
          <h1>Demarcação da propriedade</h1>
          <MapContainer>
            <OLMap id={params.itemId} />
          </MapContainer>
        </div>

        <div>
          <h1>Contato</h1>
          <PropertieInfo>
            <div>
              <h3>Telefone</h3>
              <p>{propertieData.Telefone}</p>
            </div>
            <div>
              <h3>Email</h3>
              <p>{propertieData.Email}</p>
            </div>
          </PropertieInfo>
        </div>

        <Charact propertieId={params.itemId} />

        <FilesPreview files={preview} />
      </InfoProvider>
    </Container>
  );
};
