import React, { useCallback, useEffect, useState } from "react";
import TileWMS from "ol/source/TileWMS";

import * as proj from "ol/proj";
import { Tile as TileLayer } from "ol/layer";
import View from "ol/View";

import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleDown,
} from "react-icons/hi";

import { useHistory } from "react-router-dom";

import axios from "axios";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { useOlMap } from "../../hooks/useMap";
import { useLayers } from "../../hooks";
import { useOpacity, IOpacity } from "../../hooks/opacity";

import { fetchLayers, ILayers } from "./controller";
import { LayersMenu } from "./components";

import { MapContainer, LayerButton } from "./styles";
import { ConfirmWindow } from "../ConfirmWindow";
import api from "../../service/api";

interface IButtonPosition {
  x: string | number;
  y: string | number;
}

const OLMap: React.FC = () => {
  const { push } = useHistory();
  const {
    map,
    removeControllers,
    setGoogleMaps,
    setOpenStreetMap,
    setView,
  } = useOlMap({ mapId: "map" });

  const { selectedLayers } = useLayers();
  const { opacity } = useOpacity();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [nirfValue, setNirfValue] = useState<string>();
  const [isNirfNull, setIsNirfNull] = useState<boolean>(false);
  const [idValue, setidValue] = useState<string>();
  const [layersData, setLayersData] = useState<ILayers[]>();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [
    layersButtonPosition,
    setLayersButtonPosition,
  ] = useState<IButtonPosition>({
    x: 0,
    y: 0,
  });

  const handleLoadLayers = useCallback(async () => {
    if (map && layersData) {
      layersData.forEach((layerInfo) => {
        const layer = new TileLayer({
          properties: {
            title: layerInfo["Nome Geoserve"],
          },
          source: new TileWMS({
            url: "http://191.252.93.99:8085/geoserver/wms",
            params: { LAYERS: layerInfo["Nome Geoserve"] },
            serverType: "geoserver",
            hidpi: false,
            transition: 0,
          }),
          visible: false,
        });

        map.addLayer(layer);
      });
    }
  }, [map, layersData]);

  const getInfos = useCallback(async () => {
    if (map && map !== undefined) {
      map.on("singleclick", async function hasNirf(evt) {
        const wmsSource = new TileWMS({
          url: "http://191.252.93.99:8085/geoserver/wms",
          params: { LAYERS: "itrjgv:lotes teste" },
          serverType: "geoserver",
          hidpi: false,
          transition: 0,
        });
        const view = map.getView();
        const viewResolution = view.getResolution();
        const url = wmsSource.getFeatureInfoUrl(
          evt.coordinate,
          viewResolution,
          "EPSG:3857",
          { INFO_FORMAT: "application/json" }
          // https://docs.geoserver.org/stable/en/user/services/wms/reference.html#getfeatureinfo
        );
        if (url) {
          const { data } = await axios.get(url);

          if (data.features.length > 0) {
            const { nirf } = data.features[0].properties;
            if (nirf == null) {
              setIsNirfNull(true);
            }
            const [tableName, id] = data.features[0].id.split(".");

            setShowConfirm(true);
            setNirfValue(nirf);
            setidValue(id);
          }
        }
      });
    }
  }, [map]);

  useEffect(() => {
    getInfos();
  }, [getInfos]);

  const handleView = useCallback(() => {
    const center = proj.transform(
      [-49.7082739, -24.2481922],
      "EPSG:4326",
      "EPSG:3857"
    );

    setView(
      new View({
        center,
        zoom: 12,
      })
    );
  }, [setView]);

  const configureMap = useCallback(() => {
    removeControllers();
    setOpenStreetMap();
    setGoogleMaps();
    handleView();
    handleLoadLayers();
  }, [
    removeControllers,
    setOpenStreetMap,
    setGoogleMaps,
    handleView,
    handleLoadLayers,
  ]);

  useEffect(() => {
    configureMap();
  }, [configureMap]);

  const handleFetchLayers = useCallback(async () => {
    const { content } = await fetchLayers();

    setLayersData(content);
  }, []);

  useEffect(() => {
    handleFetchLayers();
  }, [handleFetchLayers]);

  const changeLayersVisibility = useCallback(
    (layers: string[]) => {
      if (map && layersData) {
        const setedLayers = map.getLayers();
        setedLayers.forEach((element) => {
          if (
            element.hasProperties() &&
            element.getProperties().title &&
            layers
          ) {
            element.setVisible(layers.includes(element.getProperties().title));
          }
        });
      }
    },
    [map, layersData]
  );

  useEffect(() => {
    changeLayersVisibility(selectedLayers);
  }, [changeLayersVisibility, selectedLayers]);

  const handleLayersOpacity = useCallback(
    (opacities: IOpacity) => {
      if (map && layersData) {
        const setedLayers = map.getLayers();

        setedLayers.forEach((element) => {
          if (element.hasProperties() && element.getProperties().title) {
            if (element.getProperties().title === opacities.layer) {
              element.setOpacity(opacities.opacity);
            }
          }
        });
      }
    },
    [map, layersData]
  );

  useEffect(() => {
    if (opacity) {
      handleLayersOpacity(opacity);
    }
  }, [handleLayersOpacity, opacity]);

  const toggleMenu = useCallback(() => {
    setLayersButtonPosition({
      x: !showMenu ? "287px" : "0px",
      y: 0,
    });
    setShowMenu(!showMenu);
  }, [showMenu]);

  const handleCancel = useCallback(() => {
    setShowConfirm(!showConfirm);
  }, [showConfirm]);

  const handleConfirm = useCallback(async () => {
    if (nirfValue === null) {
      push(`/propertie?cId=${idValue}`);
    } else {
      const { data } = await api.get(`pages/add-nirf/${nirfValue}`);
      if (data.length > 0) {
        push(`modulos/${btoa("1")}/properties/${data[0].prop_id}`);
      } else {
        handleCancel();
      }
    }
  }, [handleCancel, idValue, nirfValue, push]);

  return (
    <>
      {layersData && showMenu && <LayersMenu layers={layersData} />}
      <MapContainer id="map" />
      <LayerButton
        buttonPosition={layersButtonPosition}
        type="button"
        onClick={toggleMenu}
      >
        {!showMenu ? (
          <>
            <p>Seletor de camadas</p>
            <HiOutlineChevronDoubleDown />
          </>
        ) : (
          <HiOutlineChevronDoubleLeft />
        )}
      </LayerButton>

      {showConfirm && idValue && nirfValue && (
        <ConfirmWindow
          doAfterCancel={handleCancel}
          doAfterConfirm={handleConfirm}
          option1="Não"
          icon1={MdCancel}
          option2="Sim"
          icon2={FaCheckCircle}
          text="Deseja visualizar as informações sobre a propriedade?"
        />
      )}
      {showConfirm && isNirfNull && (
        <ConfirmWindow
          doAfterCancel={handleCancel}
          doAfterConfirm={handleConfirm}
          option1="Não"
          icon1={MdCancel}
          option2="Sim"
          icon2={FaCheckCircle}
          text="Deseja adicionar informações sobre esta propriedade?"
        />
      )}
    </>
  );
};
export default OLMap;
