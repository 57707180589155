import React, { useState, HTMLProps, useCallback } from "react";
import * as fa from "react-icons/fa";
import { Link } from "react-router-dom";

import { ButtonsContainer, Button } from "./styles";

type ButtonsProps = HTMLProps<HTMLButtonElement>;

const Buttons: React.FC<ButtonsProps> = ({ onClick, disabled, children }) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <Button
      isFocused={isFocused}
      disabled={disabled}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        setIsFocused(!isFocused);
      }}
    >
      {children}
    </Button>
  );
};

interface TopRightButtonsProps {
  handleFiltros(): void;
  handleReports(state: boolean): void;
  handleRegister(state: boolean): void;
  handleFields(state: boolean): void;
  buttonsToShow: string[];
  disableButtons: boolean;
}

export const TopRightButtons: React.FC<TopRightButtonsProps> = ({
  handleFiltros,
  handleReports,
  handleRegister,
  handleFields,
  buttonsToShow,
  disableButtons,
}) => {
  const renderButtons = useCallback(
    (button: string) => {
      switch (button) {
        case "filters":
          return (
            <Buttons
              type="button"
              onClick={handleFiltros}
              disabled={disableButtons}
            >
              <fa.FaFilter />
              <p>Filtros</p>
            </Buttons>
          );

        case "reports":
          return (
            <Buttons
              type="button"
              onClick={() => handleReports(true)}
              disabled={disableButtons}
            >
              <fa.FaRegNewspaper />
              <p>Relatorios</p>
            </Buttons>
          );

        case "register":
          return (
            <Buttons
              type="button"
              onClick={() => handleRegister(true)}
              disabled={disableButtons}
            >
              <fa.FaPlus />
              <p>Cadastro</p>
            </Buttons>
          );

        case "fieldsToSelect":
          return (
            <Buttons
              type="button"
              onClick={() => handleFields(true)}
              disabled={disableButtons}
            >
              <fa.FaFilter />
              <p>Filtrar campos</p>
            </Buttons>
          );

        case "propertieRegister":
          return (
            <Link to="/propertie">
              <Buttons type="button" disabled={disableButtons}>
                <fa.FaPlus />
                <p>Cadastro</p>
              </Buttons>
            </Link>
          );

        default:
          return null;
      }
    },
    [disableButtons, handleFiltros, handleRegister, handleReports, handleFields]
  );
  return (
    <ButtonsContainer>
      {buttonsToShow.map((button) => renderButtons(button))}
    </ButtonsContainer>
  );
};
