import { useState, useEffect, useCallback } from "react";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

import TileImage from "ol/source/TileImage";

interface useOlMapProps {
  mapId: string;
}

interface useOlMapReturn {
  map: Map | undefined;
  setGoogleMaps(): void;
  setOpenStreetMap(): void;
  setView(view: View): void;
  removeControllers(): void;
}

export const useOlMap = ({ mapId }: useOlMapProps): useOlMapReturn => {
  const [map, setMap] = useState<Map>();

  const removeControllers = useCallback(() => {
    if (map) {
      const olControls = map.getControls();

      olControls.forEach((element) => map.removeControl(element));
    }
  }, [map]);

  const setGoogleMaps = useCallback(() => {
    if (map) {
      const googleLayer = new TileLayer({
        source: new TileImage({
          url: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga",
        }),
      });

      map.addLayer(googleLayer);
    }
  }, [map]);

  const setOpenStreetMap = useCallback(() => {
    if (map) {
      const rasterLayer = new TileLayer({
        source: new OSM(),
      });

      map.addLayer(rasterLayer);
    }
  }, [map]);

  const setView = useCallback(
    (view: View) => {
      if (map) {
        map.setView(view);
      }
    },
    [map]
  );

  useEffect(() => {
    setMap(
      new Map({
        target: mapId,
      })
    );
  }, [mapId]);

  return { map, setView, setOpenStreetMap, setGoogleMaps, removeControllers };
};
