import Highcharts from "highcharts";

import { IPropertiesByArea } from "./interfaces";

interface SunburstChartData {
  name: string;
  value: number;
  id?: string;
  parent?: string;
}

export class ChartOptions {
  private readonly propertiesInfo: IPropertiesByArea;

  constructor(propertiesInfo: IPropertiesByArea) {
    this.propertiesInfo = propertiesInfo;
  }

  public pieChartOptions(): Highcharts.Options {
    return {
      title: {
        text: "",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Propriedades",
          type: "pie",
          cursor: "pointer",
          colorByPoint: true,
          data: this.formatToPieChartData(),
        },
      ],
    };
  }

  public sunburstChartOptions(): Highcharts.Options {
    return {
      title: {
        text: "",
      },
      series: [
        {
          type: "sunburst",
          dataLabels: {
            format: "{point.name}",
            filter: {
              property: "innerArcLength",
              operator: ">",
              value: 16,
            },
            rotationMode: "circular",
          },
          levels: [
            {
              level: 1,
              dataLabels: {
                filter: {
                  property: "outerArcLength",
                  operator: ">",
                  value: 64,
                },
              },
            },
            {
              level: 2,
              borderWidth: 2,
            },
            {
              level: 3,
              colorVariation: {
                key: "brightness",
                to: -0.5,
              },
            },
          ],
          cursor: "pointer",
          colorByPoint: true,
          data: this.formatToChartData(),
        },
      ],
    };
  }

  private formatToPieChartData() {
    const keys = Object.keys(this.propertiesInfo);
    let notDelimitedTot = 0;
    let delimitedTot = 0;

    keys.forEach((key) => {
      const { notDelimited, delimited } = this.propertiesInfo[key];

      notDelimitedTot += notDelimited;
      delimitedTot += delimited;
    });

    const tot = notDelimitedTot + delimitedTot;

    return [
      {
        name: "Demarcadas",
        y: this.porcentagem(delimitedTot, tot),
        sliced: true,
        selected: true,
      },
      {
        name: "Não Demarcadas",
        y: this.porcentagem(notDelimitedTot, tot),
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  private porcentagem(value: number, total: number): number {
    const decimal = (value * 100) / total;

    return parseFloat(decimal.toFixed(2));
  }

  private formatToChartData(): SunburstChartData[] {
    let allProperties = 0;
    const chartData: SunburstChartData[] = [];

    const keys = Object.keys(this.propertiesInfo);

    keys.forEach((key) => {
      const { total } = this.propertiesInfo[key];

      allProperties += total;

      chartData.push(
        {
          name: `${key} ha`,
          value: total,
          parent: "tot",
          id: key,
        },
        {
          name: "Não demarcados",
          parent: key,
          value: this.propertiesInfo[key].notDelimited,
        },
        {
          name: "Demarcados",
          parent: key,
          value: this.propertiesInfo[key].delimited,
        }
      );
    });

    chartData.push({
      name: "Propriedades",
      value: allProperties,
      id: "tot",
    });

    return chartData;
  }
}
