/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, useState, RefObject } from "react";

import { FormHandles } from "@unform/core";
import { Select } from "../../../../../../../../components";
import api from "../../../../../../../../service/api";

import { IDefaultOptions } from "../../../../../../../../interfaces/IDefaultPages";

interface IPropertiesTypeResponse {
  content: { Descrição: string; Ordem: string; id: number }[];
}

interface PropertieTypeFieldProps {
  propertieType: number | undefined;
  formRef: RefObject<FormHandles>;
}

export const PropertieTypeField: React.FC<PropertieTypeFieldProps> = ({
  propertieType,
  formRef,
}) => {
  const [propertiesType, setPropertiesType] = useState<IDefaultOptions[]>();

  const fetchPropertiesType = useCallback(async () => {
    const { data }: { data: IPropertiesTypeResponse } = await api.get(
      "/pages/properties-type?table=false&orderByAsc=Ordem"
    );

    const propertiesTypeOptions: IDefaultOptions[] = data.content.map(
      (element) => ({
        label: element.Descrição,
        value: element.id.toString(),
      })
    );

    setPropertiesType(propertiesTypeOptions);
  }, []);

  useEffect(() => {
    fetchPropertiesType();
  }, [fetchPropertiesType]);

  const handlePropertie = useCallback(() => {
    if (propertiesType && propertieType) {
      formRef.current?.setData({
        properties: {
          "Tipo Imóvel": propertieType,
        },
      });
    }
  }, [formRef, propertiesType, propertieType]);

  useEffect(() => {
    handlePropertie();
  }, [handlePropertie]);

  return (
    <div>
      <label>Tipo do imóvel</label>
      <Select
        name="Tipo Imóvel"
        isClearable
        placeholder="Selecione um tipo"
        options={propertiesType}
      />
    </div>
  );
};
