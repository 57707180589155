/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect } from "react";
import { OptionTypeBase } from "react-select";
import Select, { Props } from "react-select/async";
import { useField } from "@unform/core";

import QuickRegister from "../QuickRegister";

interface AsyncSelectProps extends Props<OptionTypeBase, true> {
  name: string;
  quickRegister?: string;
}

export const AsyncSelect: React.FC<AsyncSelectProps> = ({
  name,
  quickRegister,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.select.state.value) {
            return [];
          }

          return ref.select.state.value.map(
            (option: OptionTypeBase) => option.value
          );
        }
        if (!ref.select.state.value) {
          return "";
        }

        return ref.select.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <>
      <Select
        cacheOptions
        ref={selectRef}
        classNamePrefix="async-react-select"
        {...rest}
      />
      {quickRegister && <QuickRegister url={quickRegister} />}
    </>
  );
};
