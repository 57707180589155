import React from "react";

import { Container } from "./styles";

interface TitleProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
}

const Title: React.FC<TitleProps> = ({ title, style }) => (
  <Container style={style}>
    <h1>{title}</h1>
  </Container>
);

export default Title;
