/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from "react";
import api from "../../../../../../service/api";
import { Response } from "../../../CreatePropertie/types";

import { PropertieInfo } from "../../styles";

interface IQuestionsAndAnswers {
  [key: string]: { question: string; answer: string }[];
}

interface CharactProps {
  propertieId: string;
}

export const Charact: React.FC<CharactProps> = ({ propertieId }) => {
  const [
    charcAnsAndQuestions,
    setCharcAnsAndQuestions,
  ] = useState<IQuestionsAndAnswers>();
  const [groups, setGroups] = useState<string[]>([]);

  const fetchCaractPergunta = useCallback(async () => {
    const { data }: { data: Response } = await api.get(
      "/pages/CharacteristicQuestions?table=false"
    );

    const answers = await api.get(
      `pages/propertiesCharacteristicsAnswers?table=false&Propriedade=${propertieId}`
    );

    const { content } = answers.data;

    const questionsAndAwnsers: IQuestionsAndAnswers = {} as IQuestionsAndAnswers;

    data.content.forEach((element) => {
      const { Grupo, Descrição, id } = element;

      const charcAnswer = content.find((item: any) => item.Pergunta === id);

      if (!questionsAndAwnsers[Grupo]) {
        questionsAndAwnsers[Grupo] = [
          {
            question: Descrição,
            answer: charcAnswer ? charcAnswer.Resposta : "------",
          },
        ];
      } else {
        questionsAndAwnsers[Grupo] = [
          ...questionsAndAwnsers[Grupo],
          {
            question: Descrição,
            answer: charcAnswer ? charcAnswer.Resposta : "------",
          },
        ];
      }
    });

    setCharcAnsAndQuestions(questionsAndAwnsers);
    setGroups(Object.keys(questionsAndAwnsers));
  }, [propertieId]);

  useEffect(() => {
    fetchCaractPergunta();
  }, [fetchCaractPergunta]);

  return (
    <div>
      <h1>Características</h1>
      {groups.map((group) => (
        <>
          <h3 style={{ marginTop: 15 }}>{group}</h3>
          <PropertieInfo
            style={{
              borderBottomWidth: "1px",
              borderBottomStyle: "dotted",
              padding: "10px 0px",
              borderBottomColor: "black",
            }}
          >
            {charcAnsAndQuestions &&
              charcAnsAndQuestions[group].map((element) => (
                <div>
                  <h4>{element.question}</h4>
                  <p
                    style={{
                      color: `${
                        element.answer === "Sim"
                          ? "green"
                          : element.answer === "Não"
                          ? "red"
                          : "black"
                      }`,
                    }}
                  >
                    {element.answer}
                  </p>
                </div>
              ))}
          </PropertieInfo>
        </>
      ))}
    </div>
  );
};
