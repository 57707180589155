import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface FiltersContextData {
  filters: string | undefined;
  setFilters(currentUrl: string): void;
  saveFilters(filters: string): void;
}

const FiltersContext = createContext<FiltersContextData>(
  {} as FiltersContextData
);

const FiltersProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState<string>(() => {
    const selectedFilters = localStorage.getItem(
      `selectFilters-${window.location.pathname}`
    );

    if (selectedFilters) {
      return selectedFilters;
    }

    return "";
  });
  const saveFilters = useCallback((selectedFilters: string) => {
    const tmp = selectedFilters;

    localStorage.setItem(`selectFilters-${window.location.pathname}`, tmp);

    setFilters(tmp);
  }, []);

  useEffect(() => {
    const selectedFilters = localStorage.getItem(
      `selectFilters-${window.location.pathname}`
    );

    if (selectedFilters) setFilters(selectedFilters);
    else setFilters("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <FiltersContext.Provider value={{ filters, setFilters, saveFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

function useFilters(): FiltersContextData {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error("useFilters must be used within an FiltersProvider");
  }

  return context;
}

export { FiltersProvider, useFilters };
