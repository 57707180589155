import React, { useCallback, useState } from "react";
import { HiOutlineDocumentReport } from "react-icons/hi";
import ReactLoading from "react-loading";
import Tooltip from "@material-ui/core/Tooltip";
import { saveAs } from "file-saver";

import api from "../../../service/api";

import { ActionButton } from "../ActionButton";
import Request from "../dto";

import { LoadingContainer } from "./styles";

const ITRLaudo: React.FC<Omit<Request, "existingValues">> = ({ id }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleGenerateReport = useCallback(async () => {
    try {
      setLoading(true);

      const laudoPayload = {
        propertieId: id,
      };

      const { data } = await api.post("/documents/itr/laudo", laudoPayload);

      setTimeout(async () => {
        const laudo = await api.get(`/documents/itr/laudo/${data}`, {
          responseType: "blob",
        });
        const laudoBloc = new Blob([laudo.data], { type: "application/pdf" });

        saveAs(laudoBloc, `laudo-${Date.now()}.pdf`);

        await api.delete(`/documents/itr/laudo/${data}`);
      }, 1000);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [id]);

  if (loading) {
    return (
      <LoadingContainer>
        <ReactLoading height="20px" width="20px" color="teal" />
      </LoadingContainer>
    );
  }

  return (
    <Tooltip title="Laudo">
      <div>
        <ActionButton onClick={handleGenerateReport}>
          <HiOutlineDocumentReport color="teal" height={20} width={20} />
        </ActionButton>
      </div>
    </Tooltip>
  );
};

export default ITRLaudo;
