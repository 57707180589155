/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import { useField } from "@unform/core";
import QuickRegister from "../QuickRegister";

import { IDefaultOptions } from "../../interfaces/IDefaultPages";

import { styleInput } from "./styles";

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  quickRegister?: {
    ativo: boolean;
    url: string;
  };
  isRequired?: boolean;
  initValue?: IDefaultOptions | IDefaultOptions[] | undefined;
}

const Select: React.FC<Props> = ({
  name,
  quickRegister = { ativo: false, url: "" },
  initValue,
  ...rest
}) => {
  const selectRef = useRef(null);
  const [value, setValue] = useState<
    IDefaultOptions | IDefaultOptions[] | undefined
  >();

  const handleChangeValue = useCallback((data: any) => {
    setValue(data);
  }, []);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
      setValue: (ref, value1) => {
        if (ref.props.options && value1 !== undefined) {
          const selected = ref.props.options.find(
            (element: any) => element.value === value1
          );

          ref.select.setValue(selected);
        }
      },
      clearValue: (ref) => {
        ref.select.clearValue();
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ReactSelect
        defaultValue={defaultValue}
        value={value}
        onChange={handleChangeValue}
        ref={selectRef}
        classNamePrefix="react-select"
        styles={styleInput}
        {...rest}
      />
      {quickRegister?.ativo ? <QuickRegister url={quickRegister.url} /> : null}
    </div>
  );
};
export default Select;
