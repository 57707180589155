import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow: auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  grid-template-rows: repeat(auto, 65px);
  column-gap: 15px;

  height: 400px;

  z-index: 2;
  padding: 7.5px;
  padding-right: 15px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
