import React, { useCallback, useRef } from "react";
import { FiMail, FiLock } from "react-icons/fi";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useAuth, SignInCredentials } from "../../../hooks/auth";
import Input from "../../../components/Input";

import logoSemv from "../../../assets/images/logo_semv_ok.jpg";

import { Container, Button } from "./styles";
import yupValidation from "../../../utils/yupValidation";

const schema = Yup.object().shape({
  email: Yup.string().required("Email obrigatório"),
  password: Yup.string().required("Senha obrigatória"),
});

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async ({ email, password }: SignInCredentials) => {
      try {
        await schema.validate(
          { email, password },
          {
            abortEarly: false,
          }
        );

        await signIn({
          email,
          password,
        });
      } catch (err) {
        const validationErrors = yupValidation(err);

        formRef.current?.setErrors(validationErrors);
      }
    },
    [signIn]
  );

  return (
    <Container>
      <div>
        <img src={logoSemv} alt="SEMV" />
      </div>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="email" icon={FiMail} placeholder="Email" />

        <Input
          name="password"
          icon={FiLock}
          type="password"
          placeholder="Senha"
        />

        <Button type="submit">Entrar</Button>
      </Form>
    </Container>
  );
};

export default SignIn;
