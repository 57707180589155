import styled from "styled-components";
import { transparentize } from "polished";

interface ReportButton {
  teste: string;
  color: string;
}

export const Container = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div & div {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const ReportButton = styled.button<ReportButton>`
  width: 12%;
  height: 80%;
  border: 1px solid ${(props) => props.color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s;

  margin-right: 10px;

  &:hover {
    opacity: 0.6;
  }

  p {
    color: ${(props) => props.color};
  }

  svg {
    color: ${(props) => props.color};
    margin-bottom: 15px;
  }
`;

export const ItemCSV = styled.button`
  width: 12%;
  height: 80%;
  border: 1px solid #5296a5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: ${transparentize(0.9, "#126b67")};
  }

  p {
    color: #5296a5;
  }

  svg {
    margin-bottom: 15px;
  }
`;

export const ItemTxt = styled.button`
  width: 12%;
  height: 80%;
  border: 1px solid #214e34;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: ${transparentize(0.9, "#214E34")};
  }

  p {
    color: #214e34;
  }

  svg {
    margin-bottom: 15px;
  }
`;

export const ItemPdf = styled.button`
  width: 12%;
  height: 80%;
  border: 1px solid #72195a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: ${transparentize(0.9, "#72195A")};
  }

  p {
    color: #72195a;
  }

  svg {
    margin-bottom: 15px;
  }
`;

export const ItemJson = styled.button`
  width: 12%;
  height: 80%;
  border: 1px solid #fa7d02;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: ${transparentize(0.9, "#FA7D02")};
  }

  p {
    color: #fa7d02;
  }

  svg {
    margin-bottom: 15px;
  }
`;
