/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from "react";

import { OptionTypeBase } from "react-select";

import api from "../../../../../../../../service/api";

import { IDefaultOptions } from "../../../../../../../../interfaces/IDefaultPages";

import { AsyncSelect } from "../../../../../../../../components";

interface OwnerFieldProps {
  propertieId: string | undefined;
}

export const OwnerField: React.FC<OwnerFieldProps> = ({ propertieId }) => {
  const [selectedOwner, setSelectedOwner] = useState<IDefaultOptions[]>();
  const [personsOptions, setPersonsOptions] = useState<IDefaultOptions[]>();

  const handleChange = useCallback((data: any) => {
    setSelectedOwner(data);
  }, []);

  const fetchFilteredPersons = async (
    inputValue: string
  ): Promise<OptionTypeBase[]> => {
    const url = `pages/person?table=false&Nome=${inputValue}`;

    const { data } = await api.get(url);

    const persons: { Nome: string; Documento: string; id: number }[] =
      data.content;

    return persons.map((person) => ({
      value: person.id,
      label: `${person.Nome}/${person.Documento}`,
    }));
  };

  const fetchAllPersons = useCallback(async () => {
    const url = "pages/person?table=false";

    const { data } = await api.get(url);

    const persons: { Nome: string; Documento: string; id: number }[] =
      data.content;

    const options = persons.map((person) => ({
      value: person.id.toString(),
      label: `${person.Nome}/${person.Documento}`,
    }));

    setPersonsOptions(options);
  }, []);

  useEffect(() => {
    fetchAllPersons();
  }, [fetchAllPersons]);

  const fetchCurrentOwners = useCallback(async () => {
    if (personsOptions && propertieId) {
      const propertiesOwner = await api.get(
        `pages/propertiesPerson?Propriedade=${propertieId}&table=false`
      );

      const owners: { Proprietário: number }[] = propertiesOwner.data.content;

      const selected = personsOptions.filter((element) => {
        return !!owners.find(
          (item) => item.Proprietário.toString() === element.value
        );
      }, []);

      setSelectedOwner(selected);
    }
  }, [personsOptions, propertieId]);

  useEffect(() => {
    fetchCurrentOwners();
  }, [fetchCurrentOwners]);

  return (
    <div>
      <label>Proprietários</label>
      <AsyncSelect
        name="prop_nome_dono"
        loadOptions={(inputValue) => fetchFilteredPersons(inputValue)}
        quickRegister="/person"
        value={selectedOwner}
        onChange={handleChange}
        defaultOptions={personsOptions}
        isMulti
      />
    </div>
  );
};
