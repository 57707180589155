import api from "../../service/api";

export interface ILayers {
  id: number;
  "Nome Geoserve": string;
  Nome: string;
  Ativo: boolean;
  Ordem: number;
}

export const fetchLayers = async (): Promise<{ content: ILayers[] }> => {
  const { data } = await api.get(
    "/pages/layers?onlyContent=true&Ativo=true&orderByAsc=id"
  );

  return data;
};
