import styled from "styled-components";

export const MapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

interface LayerButtonProps {
  buttonPosition: {
    x: string | number;
    y: string | number;
  };
}

export const LayerButton = styled.button<LayerButtonProps>`
  position: relative;

  display: flex;
  align-items: center;

  padding: 1px;
  background-color: white;
  bottom: 400px;
  color: teal;
  left: ${(props) => props.buttonPosition.x};
  animation: left 3s linear 1s;

  svg {
    margin: 3px;
  }
`;
