import styled from "styled-components";

export const Container = styled.div`
  font-size: 1.3em;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 5px;

  height: 150px;
  width: 600px;
  background-color: white;

  box-shadow: 0px 0px 15px -5px #666;

  & > div {
    display: flex;
    flex-direction: row;
  }
`;
