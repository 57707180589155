/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import { Input, Select } from "../../../../../components";
import { InfoProvider } from "../styles";

export const AddresFields: React.FC = () => {
  return (
    <InfoProvider>
      <h2>Endereço</h2>
      <div>
        <div>
          <label htmlFor="CEP">CEP</label>
          <Input name="CEP" type="number" />
        </div>

        <div>
          <label>Logradouro</label>
          <Input name="Logradouro" maxLength={50} />
        </div>

        <div>
          <label>N Logradouro</label>
          <Input name="N Logradouro" maxLength={10} />
        </div>

        <div>
          <label>Complemento</label>
          <Input name="Complemento" maxLength={100} />
        </div>

        <div>
          <label>Caixa Postal</label>
          <Input name="Caixa postal" type="string" maxLength={10} />
        </div>

        <div>
          <label>Município</label>

          <Select
            name="Município"
            required
            isDisabled
            value={{ label: "Jaguariaíva", value: "Jaguariaíva" }}
          />
        </div>

        <div>
          <label>Localização</label>
          <Input name="Localização" maxLength={100} />
        </div>
      </div>
    </InfoProvider>
  );
};
