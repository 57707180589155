/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback, RefObject } from "react";

import { FormHandles } from "@unform/core";
import api from "../../../../../../service/api";
import { Input, Select, Loading } from "../../../../../../components";
import verifyIfContentIsEmpty from "../../../../../../utils/verifyIfContentIsEmpty";
import { InfoProvider } from "../../styles";
import { IQuestions, IQuestionsResponse, Response } from "../../types";

interface InputsProps {
  questions: Omit<IQuestionsResponse, "Grupo">;
}

interface IAnswers {
  id: number;
  Pergunta: number;
  Propriedade: number;
  "Descrição pergunta": string;
  Resposta: string;
}

const Inputs: React.FC<InputsProps> = ({ questions }) => {
  switch (questions.Tipo) {
    case "Texto":
      return <Input name={`${questions.Descrição}-${questions.id}`} />;

    case "Decimal":
      return (
        <Input
          name={`${questions.Descrição}-${questions.id}`}
          type="number"
          step="any"
        />
      );

    case "Boolean":
      return (
        <Select
          name={`${questions.Descrição}-${questions.id}`}
          options={[
            { label: "Sim", value: "Sim" },
            { label: "Não", value: "Não" },
          ]}
          defaultValue={{ label: "Não", value: "Não" }}
        />
      );

    default:
      return null;
  }
};

interface CharacteristicsFieldsProps {
  id: string | undefined;
  formRef: RefObject<FormHandles>;
}

export const CharacteristicsFields: React.FC<CharacteristicsFieldsProps> = ({
  id,
  formRef,
}) => {
  const [caractPergunta, setCaractPergunta] = useState<IQuestions>();
  const [groups, setGroups] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCaractPergunta = useCallback(async () => {
    try {
      const { data }: { data: Response } = await api.get(
        "/pages/CharacteristicQuestions?table=false"
      );

      const questions: IQuestions = {} as IQuestions;

      data.content.forEach((element) => {
        const { Grupo, ...rest } = element;

        if (!questions[Grupo]) {
          questions[Grupo] = [rest];
        } else {
          questions[Grupo] = [...questions[Grupo], rest];
        }
      });

      setCaractPergunta(questions);
      setGroups(Object.keys(questions));
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCaractPergunta();
  }, [fetchCaractPergunta]);

  const fetchAnswers = useCallback(async () => {
    if (id && caractPergunta) {
      const { data } = await api.get(
        `pages/propertiesCharacteristicsAnswers?table=false&Propriedade=${id}`
      );

      const answers: IAnswers[] = data.content;

      const isEmpty = verifyIfContentIsEmpty(data.content);

      if (!isEmpty) {
        const values: { [key: string]: string } = {};

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        answers.forEach((element) => {
          values[`${element["Descrição pergunta"]}-${element.Pergunta}`] =
            element.Resposta;
        });

        formRef.current?.setData({ questions: values });
      }
    }
  }, [formRef, id, caractPergunta]);

  useEffect(() => {
    fetchAnswers();
  }, [fetchAnswers]);

  if (loading) {
    return <Loading />;
  }

  if (!caractPergunta) {
    return null;
  }

  return (
    <InfoProvider>
      <h2>Características</h2>
      {groups &&
        groups.map((group) => (
          <>
            <h4 style={{ marginTop: 15 }}>{group}</h4>
            <div>
              {caractPergunta[group].map((question) => (
                <div>
                  <label>{question.Descrição}</label>
                  <Inputs questions={question} />
                </div>
              ))}
            </div>
          </>
        ))}
    </InfoProvider>
  );
};
