import styled from "styled-components";

interface ButtonProps {
  color: string;
}

export const Button = styled.button<ButtonProps>`
  width: 100%;
  max-width: 200px;
  min-width: 150px;

  height: 100%;
  min-height: 50px;
  max-height: 55px;

  display: flex;

  align-items: center;
  justify-content: center;

  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  background: #fff;
  margin-right: 15px;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: ${(props) => props.color};
    color: #fff;
  }
`;
