import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { IconBaseProps } from "react-icons";
import { useField } from "@unform/core";
import QuickRegister from "../QuickRegister";

import { Container, MessageError } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  quickRegister?: {
    ativo: boolean;
    url: string;
  };
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  quickRegister = { ativo: false, url: "" },
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error, clearError } = useField(name);

  const onFocus = useCallback(() => {
    clearError();
  }, [clearError]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);
  return (
    <>
      {error && <MessageError>{error}</MessageError>}
      <Container
        style={{
          borderColor: `${rest.required || error ? "red" : "#333"}`,
          display: `${rest.hidden ? "none" : null}`,
        }}
      >
        {Icon && <Icon size={20} color={error && "red"} />}
        <input ref={inputRef} {...rest} onFocus={onFocus} />
        {quickRegister?.ativo ? (
          <QuickRegister url={quickRegister.url} />
        ) : null}
      </Container>
    </>
  );
};

export default Input;
