import { createContext, useContext, useState } from "react";

export interface IOpacity {
  opacity: number;
  layer: string;
}

interface OpacityContextData {
  opacity: IOpacity;
  setOpacity: React.Dispatch<React.SetStateAction<IOpacity>>;
}

const OpacityContext = createContext<OpacityContextData>(
  {} as OpacityContextData
);

const OpacityProvider: React.FC = ({ children }) => {
  const [opacity, setOpacity] = useState<IOpacity>({} as IOpacity);

  return (
    <OpacityContext.Provider value={{ opacity, setOpacity }}>
      {children}
    </OpacityContext.Provider>
  );
};

function useOpacity(): OpacityContextData {
  const context = useContext(OpacityContext);

  if (!context) {
    throw new Error("useOpacity must be used within an OpacityProvider");
  }

  return context;
}

export { OpacityProvider, useOpacity };
