import React, { HTMLAttributes } from "react";

import { Button } from "./styles";

interface MainButtonProps extends HTMLAttributes<HTMLButtonElement> {
  buttonColor?: string;
  type: "button" | "submit" | "reset";
}

export const MainButton: React.FC<MainButtonProps> = ({
  children,
  buttonColor = "teal",
  type,
  ...rest
}) => {
  return (
    <Button color={buttonColor} type={type} {...rest}>
      {children}
    </Button>
  );
};
