import styled from "styled-components";

export const Cont = styled.div`
  margin: 10px 35px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto, 65px);
  grid-gap: 15px;

  div {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const Container = styled.div`
  display: flex;
`;
export const ButtonBuscar = styled.button`
  width: 15%;
  max-width: 150px;
  min-width: 100px;
  min-height: 40px;
  max-height: 45px;
  border: 1px solid #1a8080;
  color: #1a8080;
  background: #fff;
  margin-right: 15px;
  margin-top: 10px;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: #1a8080;
    color: #fff;
  }
`;
