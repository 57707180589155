import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";

import Select from "react-select";
import { usePagination } from "../../../../../../hooks/pagination";

import { FooterContainer, SelectContainter } from "./styles";

interface FooterProps {
  quantityOfItems: number;
  numberOfPages: number;
  quantityOfItemsDisplayed: number | undefined;
}

export const Footer: React.FC<FooterProps> = ({
  numberOfPages,
  quantityOfItems,
  quantityOfItemsDisplayed,
}) => {
  const {
    currentPage,
    setCurrentPage,
    currentLimit,
    savePage,
    setCurrentLimit,
  } = usePagination();
  const [count, setCount] = useState<number>();
  const options = [
    { value: "20", label: "20 itens" },
    { value: "40", label: "40 itens" },
    { value: "60", label: "60 itens" },
    { value: "100", label: "100 itens" },
  ];
  const handleChange = (event: unknown, page: number) => {
    setCurrentPage(page);
    savePage(page);
  };
  const handleSelect = (e: any) => {
    setCurrentLimit(e.value);
  };

  useEffect(() => {
    setCount(numberOfPages);
  }, [numberOfPages]);

  return (
    <FooterContainer>
      <span>{quantityOfItems} Registros</span>
      <span>{quantityOfItemsDisplayed || 0} Exibidos</span>
      <SelectContainter>
        <Select
          onChange={handleSelect}
          options={options}
          name="Tipo Imóvel"
          isClearable
          placeholder="Exibir"
          menuPlacement="auto"
        />
      </SelectContainter>

      {currentLimit && (
        <Pagination
          count={count}
          onChange={handleChange}
          size="medium"
          page={currentPage}
        />
      )}
    </FooterContainer>
  );
};
