/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import styled from "styled-components";

import { ToastContainer, ToastContainerProps, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

interface PropsToast {
  type?: string;
  message?: string;
}

export const WrappedToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastContainer {...rest} />
  </div>
);

const StyledContainer = styled(WrappedToastContainer).attrs({
  className: "toast-container",
  toastClassName: "toast",
  bodyClassName: "body",
  progressClassName: "progress",
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    background: #2196f3;
    border-radius: 5px;
    box-shadow: 0px 0px 15px -5px #555;
  }
  .Toastify__toast--error {
    background: #f44336;
  }
  .Toastify__toast--warning {
    background: #ff9800;
  }
  .Toastify__toast--success {
    background: #4caf50;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
  }
`;

export const showToast = ({ type, message }: PropsToast) => {
  switch (type) {
    case "success":
      return toast.success(message);
    case "warn":
      return toast.warn(message);
    case "error":
      return toast.error(message);
    default:
      return toast.info(message);
  }
};

export default function ToastAnimated() {
  return <StyledContainer autoClose={8000} />;
}
