import styled from "styled-components";

export const Container = styled.div``;

export const InfoProvider = styled.div`
  margin: 5px 15px;
  border-radius: 2.5px;
  box-shadow: 0px 0px 15px -5px #666;
  flex-direction: row;

  & > div {
    padding: 35px 0px;
    margin: 0px 25px;
    border-bottom: 1px solid teal;
    flex-direction: column;

    & > div {
      margin-top: 25px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(auto, 65px);
      grid-gap: 15px;
    }
  }
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 300px;
`;

export const PropertieInfo = styled.div`
  display: flex;
`;
