import styled from "styled-components";

export const Modal = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 90%;
  height: 90%;

  border-radius: 8.5px;
  border: 1px solid #eee;

  padding: 35px;

  header {
    display: flex;

    justify-content: space-between;
    align-items: center;

    width: 100%;

    border-bottom: 1px solid #999;

    margin-bottom: 25px;
  }
`;

export const CloseButton = styled.button`
  border-radius: 50%;
  color: #f44336;

  height: 20px;
  width: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f44336;
    color: white;
  }
`;

export const FieldsContainer = styled.div`
  width: 100%;

  padding: 12.5px;
  overflow: auto;
`;
