import React from "react";
import { Link } from "react-router-dom";

import { FaHome } from "react-icons/fa";

import { Container } from "./styles";

const HomeButton: React.FC = () => {
  return (
    <Link to="/dashboard">
      <Container>
        <FaHome size={28} />
        <p>Inicio</p>
      </Container>
    </Link>
  );
};

export default HomeButton;
