import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 3;

  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
