/* eslint-disable @typescript-eslint/ban-types */
import React from "react";

import {
  Delete,
  Clone,
  Edit,
  Show,
  EditPropertie,
  ITRReport,
  SendPassword,
} from "../../../ActionsButtons";
import ITRLaudo from "../../../ActionsButtons/ITRLaudo";

interface ActionButtonsProps {
  buttonType: string;
  id: number;
  handleExistingValues: Function;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  buttonType,
  id,
  handleExistingValues,
}) => {
  switch (buttonType) {
    case "edit":
      return <Edit id={id} existingValues={handleExistingValues} />;
    case "clone":
      return <Clone id={id} existingValues={handleExistingValues} />;
    case "delete":
      return <Delete id={id} />;
    case "show":
      return <Show id={id} />;
    case "editPropertie":
      return <EditPropertie id={id} />;
    case "itrReport":
      return <ITRReport id={id} />;
    case "itrLaudo":
      return <ITRLaudo id={id} />;
    case "sendPassword":
      return <SendPassword id={id} />;
    default:
      return null;
  }
};
