import styled from "styled-components";

export const ButtonPlus = styled.button`
  color: #666;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  svg {
    margin: 0;
  }

  &:hover {
    background-color: teal;
    color: #fff;
  }
`;
