import { HTMLProps } from "react";

import styled, { css } from "styled-components";

interface ButtonFiltroProps extends HTMLProps<HTMLButtonElement> {
  isFocused: boolean;
}

export const FiltersContainer = styled.section``;

export const ButtonsContainer = styled.section`
  position: absolute;
  right: 0;
  display: flex;
  z-index: 2;

  margin-top: -30px;
  margin-right: 19px;
`;

export const Button = styled.button<ButtonFiltroProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 12px;
  height: 31px;
  padding: 15px 15px 10px 15px;
  border: 1px solid #fff;
  border-top-left-radius: 3.5px;
  border-top-right-radius: 3.5px;
  margin-right: 2px;

  svg {
    font-size: 11px;
    color: #333;
  }

  p {
    text-align: center;
    margin-left: 3px;
  }

  ${(props) =>
    props.isFocused &&
    css`
      border: 1px solid #ccc;
      border-color: #bbb;
      border-bottom: 2px solid #fff;
      background-color: #fff;

      p,
      svg {
        color: teal;
      }
    `}

  &:hover {
    border-color: #bbb;
    border-bottom: 2px solid #fff;
    background-color: #fff;

    p,
    svg {
      color: teal;
    }
  }
`;
