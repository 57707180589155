import React from "react";
import { BrowserRouter } from "react-router-dom";
import axiosMiddleware from "./middleware/axios";
import "./assets/Icons/index.js";
import "react-toastify/dist/ReactToastify.css";
import ToastAnimated from "./components/CustomToast";
import GlobalStyle from "./styles/global";
import Routes from "./routes";

import { AuthProvider } from "./hooks/auth";

axiosMiddleware();

const App: React.FC = () => (
  <>
    <AuthProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <ToastAnimated />
    </AuthProvider>
    <GlobalStyle />
  </>
);

export default App;
