/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useCallback, RefObject, useState } from "react";

import { FormHandles } from "@unform/core";

import { useParams } from "react-router-dom";
import { Input } from "../../../../../../components";
import api from "../../../../../../service/api";

import { OwnerField, PropertieTypeField } from "./components";
import { InfoProvider } from "../../styles";
import { IPropertiesResponse } from "../../../../../../interfaces/IProperties";

interface PropertieFieldsProps {
  id: string | undefined;
  formRef: RefObject<FormHandles>;
  cId?: string;
}

export const PropertieFields: React.FC<PropertieFieldsProps> = ({
  formRef,
  id,
}) => {
  const [currentPropertieType, setCurrentPropertieType] = useState<number>();

  const fetchValues = useCallback(async () => {
    if (id) {
      const { data } = await api.get(`pages/properties/${id}?table=false`);

      const propertie: IPropertiesResponse = data;

      setCurrentPropertieType(propertie["Tipo Imóvel"]);

      delete propertie["Tipo Imóvel"];

      formRef.current?.setData({ properties: { ...data } });
    }
  }, [id, formRef]);

  useEffect(() => {
    fetchValues();
  }, [fetchValues]);

  return (
    <InfoProvider>
      <h2>Propriedade</h2>

      <div>
        <div>
          <label>ANO</label>
          <Input name="Ano" required type="number" />
        </div>

        <div>
          <label>Número do NIRF</label>
          <Input name="NIRF" required maxLength={20} />
        </div>

        <div>
          <label>Nome propriedade</label>
          <Input name="Nome Propriedade" required />
        </div>

        <PropertieTypeField
          formRef={formRef}
          propertieType={currentPropertieType}
        />

        <div>
          <label>Área declarada (ha)</label>
          <Input name="Área declarada" required type="number" step="any" />
        </div>

        <OwnerField propertieId={id} />

        <div>
          <label>Código do INCRA</label>
          <Input name="Código Incra" maxLength={30} />
        </div>

        <div>
          <label>Inscrição municipal</label>
          <Input name="Inscrição muncipal" maxLength={20} />
        </div>

        <div>
          <label> Número do CAR</label>
          <Input name="CAR" />
        </div>
      </div>
    </InfoProvider>
  );
};
